import { FC } from 'react';
import { NoNotesContainer, NoNotesTextBox } from 'components/notes/noNotes/NoNotes.styles';
import { Body1, ehiTheme, H4 } from '@ehi/ui';

type NoNotesProps = {
  title: string;
  description: string;
};

export const NoNotes: FC<NoNotesProps> = ({ title, description }) => {
  return (
    <NoNotesContainer data-testid='noNotes'>
      <NoNotesTextBox>
        <H4 color={ehiTheme.palette.text.disabled} data-testid={'noNotesTitle'}>
          {title}
        </H4>
        <Body1 data-testid={'noNotesDescription'} color='textSecondary'>
          {description}
        </Body1>
      </NoNotesTextBox>
    </NoNotesContainer>
  );
};
