import { Box, styled } from '@mui/material';
import { ehiTheme, Subtitle2 } from '@ehi/ui';
import IconButton from '@mui/material/IconButton';

export const FilterSection = styled(Box)`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: ${ehiTheme.spacing(3, 3, 2, 3)};
  z-index: 100;
`;

export const FilterItems = styled(Box)`
  width: 75%;
  display: flex;
  justify-self: center;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFilterButton = styled(IconButton)`
  display: flex;
  padding: ${ehiTheme.spacing(1.5, 1)};
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${ehiTheme.ehi.palette.primary.main};
`;

export const StyledFilterLabel = styled(Subtitle2)`
  text-transform: uppercase;
`;
