import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDevCypressRun } from 'utils/buildInfoUtil';
import { FromEditor, FromRetrieve, ReservationState } from '/redux/slices/booking/bookingEditorSliceTypes';
import { transformToReservationData } from 'components/shared/uiModels/reservation/reservationUtils';

const win = window as any;
const initialState = (isDevCypressRun() && win?.initialState?.bookingEditor) || ({} as ReservationState);

const bookingEditorSlice = createSlice({
  name: 'bookingEditor',
  initialState: initialState as ReservationState,
  reducers: {
    setReservation: (state: ReservationState, action: PayloadAction<FromRetrieve | FromEditor>) => {
      state.reservation = transformToReservationData(action.payload);
    },
    setBookingEditorId: (state: ReservationState, action: PayloadAction<string>) => {
      state.editorId = action.payload;
    },
    setBookingEditorDirty: (state: ReservationState, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    resetReservationState: (state: ReservationState) => {
      state.editorId = undefined;
      state.reservation = undefined;
      state.isDirty = false;
    },
  },
});

export const { setBookingEditorDirty, setReservation, setBookingEditorId, resetReservationState } =
  bookingEditorSlice.actions;

export default bookingEditorSlice.reducer;
