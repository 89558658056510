import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { NoPayments } from 'components/notes/historyTab/paymentHistory/noPayments/NoPayments';
import { Body2 } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import {
  PaymentCell,
  PaymentHistoryTitle,
  StyledBox,
  StyledDateAndTime,
} from 'components/notes/historyTab/paymentHistory/PaymentHistory.styles';
import { Grid } from '@mui/material';
import { Title } from 'components/shared/ui/styles/Typography.styles';
import { Box, useMediaQuery } from '@mui/system';
import { shadowStyle } from 'components/shared/ui/styles/Global.styles';
import { HistoryCard } from 'components/notes/historyTab/HistoryTab.styles';

export const PaymentHistory: FC = () => {
  const { t } = useTranslations();
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const paymentHistories = [
    {
      date: '12/16/2023',
      time: '02:30PM',
      type: 'Credit Card',
      entry: 'Manually Entered',
      amount: '$319.00',
      cardTransactionType: 'Authorization',
      cardDetails: {
        nameProvided: 'Margaret Scott',
        cardNumber: '4859',
        expiration: {
          lastValidMonth: '03',
          year: '24',
        },
      },
      cardAttributes: {
        cardBrand: 'VISA',
      },
      status: 'Approved',
    },
    {
      date: '12/16/2023',
      time: '10:30PM',
      type: 'Credit Card',
      entry: 'Manually Entered',
      amount: '$319.00',
      cardTransactionType: 'Sale',
      cardDetails: {
        nameProvided: 'Margaret Scott',
        cardNumber: '4859',
        expiration: {
          lastValidMonth: '03',
          year: '24',
        },
      },
      cardAttributes: {
        cardBrand: 'VISA',
      },
    },
  ];

  return (
    <HistoryCard sx={{ width: isMobileOrTablet ? '100%' : '75%', boxShadow: shadowStyle }} data-testid='paymentHistory'>
      <PaymentHistoryTitle>
        <Title>
          <b>{t('notes.history.paymentHistory')}</b>
        </Title>
      </PaymentHistoryTitle>
      <EhiDivider />
      {paymentHistories.length ? (
        <>
          <Grid container>
            <PaymentCell item xs={2}>
              <Body2 bold>{t('notes.history.dateAndTime')}</Body2>
            </PaymentCell>
            <PaymentCell item xs={4}>
              <Body2 bold>{t('notes.history.paymentType')}</Body2>
            </PaymentCell>
            <PaymentCell item xs={2}>
              <Body2 bold>{t('notes.history.amount')}</Body2>
            </PaymentCell>
            <PaymentCell item xs={4}>
              <Body2 bold>{t('notes.history.transaction')}</Body2>
            </PaymentCell>
          </Grid>
          {paymentHistories.map((history, index) => {
            return (
              <Box key={index}>
                <EhiDivider />
                <Grid container>
                  <PaymentCell item xs={2}>
                    <StyledBox>
                      <StyledDateAndTime>{history.date}</StyledDateAndTime>
                      <StyledDateAndTime>{history.time}</StyledDateAndTime>
                    </StyledBox>
                  </PaymentCell>
                  <PaymentCell item xs={4}>
                    <StyledBox>
                      <Body2>
                        <b>{history.type}</b>
                        <span>,&nbsp;</span>
                        {history.entry}
                      </Body2>
                      <Body2>{history.cardDetails.nameProvided}</Body2>
                      <Body2>
                        {history.cardAttributes.cardBrand} {history.cardDetails.cardNumber}
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        {t('notes.history.exp')} {history.cardDetails.expiration.lastValidMonth}
                        {'/'}
                        {history.cardDetails.expiration.year}
                      </Body2>
                    </StyledBox>
                  </PaymentCell>
                  <PaymentCell item xs={2}>
                    <Body2>{history.amount}</Body2>
                  </PaymentCell>
                  <PaymentCell item xs={4}>
                    <StyledBox>
                      <Body2>{history.cardTransactionType}</Body2>
                      <Body2>
                        <b>{history.status}</b>
                      </Body2>
                    </StyledBox>
                  </PaymentCell>
                </Grid>
              </Box>
            );
          })}
        </>
      ) : (
        <NoPayments />
      )}
    </HistoryCard>
  );
};
