import { Box, Grid, IconButton, styled, TextField } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';

export const StyledNotesButton = styled(IconButton)<{ background?: string }>(({ background = '' }) => {
  return {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    marginLeft: ehiTheme.spacing(1.5),
    '&:hover': {
      backgroundColor: background,
    },
  };
});

export const AddNotesTextBox = styled(Box)`
  margin: ${ehiTheme.spacing(0, 3)};
`;

export const AddNotesTextField = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    padding: theme.spacing(1, 2),
  },
}));

export const AddNotesHelperText = styled(FormHelperText)`
  justify-content: space-between;
  padding: ${ehiTheme.spacing(0, 2)};
  margin-top: ${ehiTheme.spacing(0)};
  display: flex;
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
  padding: ${ehiTheme.spacing(0.5, 0)};
  height: 100%;
`;
StyledGridItem.defaultProps = {
  item: true,
};

export const NotesInputAdornment = styled(InputAdornment)`
  margin-bottom: ${ehiTheme.spacing(31.5)};
  margin-right: ${ehiTheme.spacing(-0.5)};
`;
