import { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { ehiTheme } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useTranslations } from 'components/shared/i18n';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  ActionableAlertButton,
  ActionableAlertMessageContainer,
  AlertDescriptionContainer,
  AlertDescriptionText,
} from 'components/shared/alert/AlertDialog.styles';
import {
  AlertWithActionProps,
  ERROR_ALERT_VARIANT,
  INFO_ALERT_VARIANT,
  WARNING_ALERT_VARIANT,
} from 'components/shared/alert/AlertDialogTypes';
export const AlertWithAction: FC<AlertWithActionProps> = ({ description, navigate, alertVariant }) => {
  const { t } = useTranslations();
  const { hideAlert } = useAlert();

  let alertIcon;
  if (alertVariant === INFO_ALERT_VARIANT) {
    alertIcon = (
      <ReportProblemOutlinedIcon
        color={WARNING_ALERT_VARIANT}
        style={{ color: '#E37222', paddingRight: ehiTheme.spacing(1) }}
      />
    );
  } else if (alertVariant === WARNING_ALERT_VARIANT) {
    alertIcon = <InfoOutlinedIcon color={INFO_ALERT_VARIANT} style={{ paddingRight: ehiTheme.spacing(1) }} />;
  } else {
    alertIcon = <ErrorOutlineIcon color={ERROR_ALERT_VARIANT} style={{ paddingRight: ehiTheme.spacing(1) }} />;
  }

  return (
    <ActionableAlertMessageContainer data-testid='actionableAlertMessage'>
      <AlertDescriptionContainer>
        {alertIcon}
        <AlertDescriptionText>{description.message}</AlertDescriptionText>
        {description.routePath && (
          <ActionableAlertButton
            variant='text'
            data-testid='viewButton'
            onClick={(): void => {
              if (description.routePath) {
                navigate(description.routePath);
                hideAlert();
              }
            }}
            size={'small'}>
            {t('common.view')}
          </ActionableAlertButton>
        )}
      </AlertDescriptionContainer>
      <EhiDivider />
    </ActionableAlertMessageContainer>
  );
};
