import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Body2, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import {
  RateAndBillingInfoBox,
  RateAndBillingLabel,
  RateAndBillingSectionBox,
  RateAndBillingText,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { FC, useCallback, useMemo, useState } from 'react';
import { EditBillToDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialog';
import { mask } from 'utils/maskUtils';
import { parseUrn } from 'utils/urnUtils';
import { EditBillToProvider } from 'context/editBillTo/EditBillToContext';
import { useBillingAccountByAccountQuery } from 'services/businessAccount/accountQueries';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { SameAsRateSourceDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/SameAsRateSourceDialog';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { updateBusinessPayers } from 'services/booking/bookingService';
import { BillingAccountErrorCodes } from 'utils/errorUtils';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { AdditionalInformationSection } from 'components/flexFlow/rateAndBilling/AdditionalInformationSection';
import { AdditionalInfoAccountType } from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { useRetrieveAdditionalInformation } from 'components/flexFlow/rateAndBilling/additionalInformation/useRetrieveAdditionalInformation';

export const BillToSection: FC = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const [addFromRateSourceModal, setAddFromRateSourceModal] = useState(false);
  const [editBillToModal, setEditBillToModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  // Note: BillingAccount here is from Editor
  const { accountNumber, billingAccountNumber, billingNumber, billingAccountInfo, isBillingAccountInfoLoading } =
    useAccountDetails();
  const { data: availableBillingAccount, error: billingAccountErrors } = useBillingAccountByAccountQuery(accountNumber);
  const { additionalInfo, loading: isAdditionalInformationLoading } = useRetrieveAdditionalInformation(
    AdditionalInfoAccountType.BILL_TO
  );

  const isSameAsRateSourceButton = useMemo(() => {
    if (billingAccountNumber) {
      return false;
    }
    const errorCodes = billingAccountErrors?.errors?.map((message) => message.code);
    const isBillingNumberRequired =
      errorCodes?.includes(BillingAccountErrorCodes.BillingNumberIsRequired) ||
      errorCodes?.includes(BillingAccountErrorCodes.BillingNumberAlwaysRequired) ||
      errorCodes?.includes(BillingAccountErrorCodes.MoreThanOneBillingAccountNumber);

    if (!isReadOnly && (availableBillingAccount?.urn || isBillingNumberRequired)) {
      return true;
    }
  }, [availableBillingAccount?.urn, billingAccountErrors?.errors, isReadOnly, billingAccountNumber]);
  const shouldShowAdditionalInfo = additionalInfo && additionalInfo?.additionalInfoFields?.length > 0;

  const handleRemovingBillingAccount = useCallback(async () => {
    const selectedAction = await showAlert({
      variant: 'destructive',
      title: t('rateAndBilling.billTo.removeBillTo'),
      description: t('rateAndBilling.billTo.billToRemovalConfirmation'),
      primaryActionText: t('common.remove'),
      secondaryActionText: t('common.cancel'),
    });
    if (selectedAction === SelectedAction.Primary) {
      setIsUpdating(true);
      const { errors } = await updateAndRefresh(async () => updateBusinessPayers(bookingEditorId, []));
      setIsUpdating(false);
      if (errors) {
        await showAlert({ responseMessages: errors });
      }
    }
  }, [bookingEditorId, showAlert, t, updateAndRefresh]);

  return (
    <RateAndBillingSectionBox data-testid='billToSection'>
      <Box display={'flex'}>
        <GridContainer>
          <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
        </GridContainer>
        <GridContainer justifyContent={'end'}>
          {isSameAsRateSourceButton && (
            <EhiButton onClick={(): void => setAddFromRateSourceModal(true)}>
              {t('rateAndBilling.billTo.sameAsRateSource')}
            </EhiButton>
          )}
          {!isReadOnly && <EhiButton onClick={(): void => setEditBillToModal(true)}>{t('common.edit')}</EhiButton>}
        </GridContainer>
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {billingAccountNumber && <RateAndBillingText bold>{billingAccountNumber}</RateAndBillingText>}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='billingNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.billTo.billingNumber')}</RateAndBillingLabel>
          {billingNumber && <RateAndBillingText bold>{mask(billingNumber, 4)}</RateAndBillingText>}
        </Grid>
        {(billingAccountInfo?.name || billingAccountInfo?.type) && (
          <RateAndBillingInfoBox data-testid='billingAccountInfo' style={{ flexDirection: 'column' }}>
            <Grid container display={'flex'} alignItems={'center'}>
              <Grid item xs={9} sm={9}>
                <Box display={'flex'} alignItems={'center'}>
                  {billingAccountInfo?.name && (
                    <H6 data-testid='accountName' paddingRight={ehiTheme.spacing(1.5)}>
                      {billingAccountInfo?.name}
                    </H6>
                  )}
                  {billingAccountInfo?.type && (
                    <Body2 color={'#555759'} data-testid='accountType'>
                      {billingAccountInfo?.type}
                    </Body2>
                  )}
                </Box>
              </Grid>
              {!isReadOnly && (
                <Grid item xs={3} sm={3} display={'flex'} justifyContent={'flex-end'}>
                  <EhiButton
                    variant={'text'}
                    color={'error'}
                    size={'small'}
                    style={{ margin: 0 }}
                    onClick={handleRemovingBillingAccount}>
                    {t('common.remove')}
                  </EhiButton>
                </Grid>
              )}
            </Grid>
            {shouldShowAdditionalInfo && (
              <Box style={{ margin: ehiTheme.spacing(1.2, 0) }}>
                <AdditionalInformationSection additionalInfo={additionalInfo} />
              </Box>
            )}
          </RateAndBillingInfoBox>
        )}
      </Grid>
      {addFromRateSourceModal && (
        <SameAsRateSourceDialog
          billingNumber={parseUrn(availableBillingAccount?.urn)}
          onClose={(): void => setAddFromRateSourceModal(false)}
        />
      )}
      {editBillToModal && (
        <EditBillToProvider>
          <EditBillToDialog onClose={(): void => setEditBillToModal(false)} />
        </EditBillToProvider>
      )}
      <ProgressOverlay inProgress={isBillingAccountInfoLoading || isUpdating || !!isAdditionalInformationLoading} />
    </RateAndBillingSectionBox>
  );
};
