import { FC, useCallback, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useFormContext } from 'react-hook-form';
import { associateRenterToReservationEditor } from 'services/booking/bookingService';
import { Renter } from 'services/booking/bookingTypes';
import { SelectField } from 'components/shared/forms/SelectField';
import { RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { Subtitle1 } from '@ehi/ui';
import { useQuickResModalContext } from 'components/quickRes/QuickResModalContext';
import { logDebug } from 'utils/logUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { AGE_OPTIONS } from 'components/flexFlow/driver/driverForm/driverFormUtils';

const QuickResDriver: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [isUpdatingDriver, setIsUpdatingDriver] = useState<boolean>(false);
  const { onBlurFunction, setOnBlurFunction } = useQuickResModalContext();
  const { showAlert } = useAlert();

  const {
    trigger,
    getFieldState,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleOnBlur = useCallback(
    async (fieldName: string): Promise<void> => {
      const formValues = getValues();
      await trigger(fieldName);
      const lastName: string = formValues[QuickResFields.LastName];
      const firstName: string = formValues[QuickResFields.FirstName];
      const age = formValues[QuickResFields.Age];

      if (
        (getFieldState(QuickResFields.LastName).isDirty || getFieldState(QuickResFields.FirstName).isDirty) &&
        !errors[QuickResFields.LastName] &&
        !errors[QuickResFields.FirstName] &&
        !errors[QuickResFields.Age] &&
        lastName?.length > 1 &&
        firstName?.length > 1
      ) {
        setIsUpdatingDriver(true);
        try {
          const data = {
            type: RenterTypes.NoProfile,
            name: {
              given: firstName,
              surname: lastName,
            },
            age: age,
          } as Renter;

          const { errors } = await updateAndRefresh(() => associateRenterToReservationEditor(bookingEditorId, data));
          if (errors) {
            await showAlert({
              variant: 'error',
              description: errors?.[0]?.localizedMessage ?? '',
              primaryActionText: 'dismiss',
            });
            setIsUpdatingDriver(false);
            return Promise.reject({ message: errors?.[0]?.localizedMessage });
          } else {
            setIsUpdatingDriver(false);
            return Promise.resolve();
          }
        } catch (error) {
          setIsUpdatingDriver(false);
          return Promise.reject(error);
        }
      }
      return Promise.reject('form is not valid');
    },
    [bookingEditorId, errors, getFieldState, getValues, showAlert, trigger, updateAndRefresh]
  );

  const registerFirstNameOnBlurFunction = useCallback(() => {
    setOnBlurFunction(() => () => handleOnBlur(QuickResFields.FirstName));
  }, [handleOnBlur, setOnBlurFunction]);

  const registerLastNameOnBlurFunction = useCallback(() => {
    setOnBlurFunction(() => () => handleOnBlur(QuickResFields.LastName));
  }, [handleOnBlur, setOnBlurFunction]);

  const runOnBlurFunction = useCallback(() => onBlurFunction?.().catch(logDebug), [onBlurFunction]);

  return (
    <GridContainer>
      <GridItem sm={12}>
        <Subtitle1 bold data-testid={'res-driver'}>
          {t('driver.driver')}
        </Subtitle1>
      </GridItem>
      <GridItem sm={4}>
        <FormTextField
          data-testid={QuickResFields.LastName}
          name={QuickResFields.LastName}
          required={true}
          label={t('driver.lastName')}
          onFocus={registerLastNameOnBlurFunction}
          onBlur={runOnBlurFunction}
        />
      </GridItem>
      <GridItem sm={4}>
        <FormTextField
          name={QuickResFields.FirstName}
          required={true}
          label={t('driver.firstName')}
          onFocus={registerFirstNameOnBlurFunction}
          onBlur={runOnBlurFunction}
        />
      </GridItem>
      <GridItem sm={4}>
        <SelectField
          name={QuickResFields.Age}
          label={t('driver.age')}
          data-testid={QuickResFields.Age}
          options={AGE_OPTIONS}
          hasNoneOption={false}
          onChange={(event: any): void => {
            setValue(QuickResFields.Age, event.target.value);
            handleOnBlur(QuickResFields.Age);
          }}
        />
      </GridItem>
      <ProgressOverlay inProgress={isUpdatingDriver} overrideZindex={true} />
    </GridContainer>
  );
};
export default QuickResDriver;
