import { NotesTab } from 'components/notes/notesTab/NotesTab';
import { HISTORY, NOTES, PINNED } from 'utils/constants';
import { PinnedTab } from 'components/notes/pinnedTab/PinnedTab';
import { HistoryTab } from 'components/notes/historyTab/HistoryTab';
import { FC } from 'react';
import { TFunction } from 'i18next';

export type TabProps = {
  titleKey: string;
  component: FC;
  value: string;
};

export const getTabs = (t: TFunction<'translation'>): TabProps[] => {
  return [
    {
      titleKey: `${t('notes.notes')} (0)`,
      component: NotesTab,
      value: NOTES,
    },
    {
      titleKey: `${t('notes.pinned')} (0)`,
      component: PinnedTab,
      value: PINNED,
    },
    {
      titleKey: `${t('notes.history.title')} (0)`,
      component: HistoryTab,
      value: HISTORY,
    },
  ];
};
