import { Grid, styled } from '@mui/material';
import { Box } from '@mui/system';
import { Body2, ehiTheme } from '@ehi/ui';

export const PaymentCell = styled(Grid)`
  align-items: center;
  align-self: stretch;
  display: flex;
  padding: ${ehiTheme.spacing(2)};
`;

export const PaymentHistoryTitle = styled(Box)`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  padding: ${ehiTheme.spacing(2)};
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const StyledDateAndTime = styled(Body2)`
  color: #0000008a;
`;
