import { useCallback } from 'react';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import { selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { useTranslations } from 'components/shared/i18n';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { useGetAdditionalDriverProfiles } from 'services/renter/useGetAdditionalDriverProfiles';
import { DateTime } from 'luxon';
import { TFunction } from 'i18next';

const isLicenseExpired = (
  startDate: string | undefined,
  returnDate: string | undefined,
  licenseExpiration: string,
  t: TFunction
): boolean => {
  const rentalStartDate = startDate && DateTime.fromISO(startDate).toFormat(t('format.yearMonthDay'));
  const rentalReturnDate = returnDate && DateTime.fromISO(returnDate).toFormat(t('format.yearMonthDay'));
  const licenseExpirationDate = DateTime.fromISO(licenseExpiration).toFormat(t('format.yearMonthDay'));

  return (
    (rentalStartDate && licenseExpirationDate < rentalStartDate) ||
    (rentalReturnDate && licenseExpirationDate < rentalReturnDate) ||
    false
  );
};

export const useDriverLicenseValidation = (): {
  validateExpirationDateAgainstRentalDates: (date: string) => void;
  validateRentalDatesAgainstLicenseExpiration: (startDate: string, returnDate: string) => void;
} => {
  const { showAlert } = useAlert();
  const rentalPickup = useAppSelector(selectPickup);
  const rentalReturn = useAppSelector(selectReturn);
  const { driverData: primaryDriverData } = useGetRenterProfile();
  const { data: additionalDriversData } = useGetAdditionalDriverProfiles();

  const { t } = useTranslations();

  /**
   * - Validates driver license does not expire before or during rental period.
   *
   * @param date Driver license expiration date
   */
  const validateExpirationDateAgainstRentalDates = useCallback(
    (date: string) => {
      const startDate = rentalPickup?.dateTime;
      const returnDate = rentalReturn?.dateTime;

      if (!date && !(startDate || returnDate)) {
        return;
      }

      if (isLicenseExpired(startDate, returnDate, date, t)) {
        void showAlert({
          title: t('driver.warnings.expDateInPastTitle'),
          description: t('driver.warnings.expDateInPast'),
        });
      }
    },
    [rentalPickup?.dateTime, rentalReturn?.dateTime, showAlert, t]
  );

  /**
   * - Validates rental date does not fall after driver license expiration date of all drivers.
   *
   * @param date Rental pickup or return date
   */
  const validateRentalDatesAgainstLicenseExpiration = useCallback(
    (startDate: string, returnDate: string) => {
      const driverHasExpiredLicense = [primaryDriverData, ...additionalDriversData]?.some((driver) => {
        if (!driver || !driver.license || !driver?.license?.expirationDate) {
          return false;
        }
        if (driver.license.expirationDate) {
          return isLicenseExpired(startDate, returnDate, driver.license.expirationDate, t);
        }
        return false;
      });

      if (driverHasExpiredLicense) {
        void showAlert({
          title: t('driver.warnings.expDateInPastTitle'),
          description: t('driver.warnings.expDateInPast'),
        });
      }
    },
    [additionalDriversData, primaryDriverData, showAlert, t]
  );

  return {
    validateExpirationDateAgainstRentalDates,
    validateRentalDatesAgainstLicenseExpiration,
  };
};
