import { styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Card from '@mui/material/Card';
import { OverlineText } from 'components/shared/ui/styles/Typography.styles';
import IconButton from '@mui/material/IconButton';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

export const StyledNoteCard = styled(Card)`
  display: flex;
  padding: ${ehiTheme.spacing(1, 2, 2, 2)};
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: ${ehiTheme.spacing(1)};
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

export const NoteCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const NoteCardHeaderSection1 = styled('div')`
  display: flex;
  padding-right: ${ehiTheme.spacing(2)};
  align-items: center;
  gap: ${ehiTheme.spacing(1)};
`;

export const MessageIcon = styled(MessageOutlinedIcon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  color: ${ehiTheme.ehi.palette.primary.main};
`;

export const StyledCircleBackground = styled('div')`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(
    --primary-background,
    linear-gradient(0deg, rgba(77, 120, 157, 0.08) 0%, rgba(77, 120, 157, 0.08) 100%),
    #fff
  );
  border-radius: 50%;
  border: 2px solid ${ehiTheme.ehi.palette.primary.main};
`;

export const EIDChip = styled(OverlineText)`
  display: flex;
  min-width: 16px;
  min-height: 16px;
  max-height: 16px;
  padding: ${ehiTheme.spacing(0, 0.5)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  border-radius: 4px;
`;

export const NoteCardHeaderSection2 = styled('div')`
  display: flex;
  align-items: center;
`;

export const PushpinButton = styled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

export const StyledPushpinIcon = styled(PushPinIcon)`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  fill: ${ehiTheme.ehi.palette.primary.main};
`;

export const StyledPushPinOutlinedIcon = styled(PushPinOutlinedIcon)`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  fill: ${ehiTheme.palette.text.secondary};
`;
