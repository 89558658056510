import { JSX, ReactNode, useCallback, useContext } from 'react';
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuthStore } from '@ehi/auth';

import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import parser from 'ua-parser-js';

import { logError } from 'components/shared/logger/splunkLogger';
import { isDevCypressRun } from 'utils/buildInfoUtil';
import { logDebug } from 'utils/logUtils';
import { getDefaultFeatureFlagsValues } from 'utils/featureFlagUtils';
import { SecretsContext } from 'context/secrets/SecretsContext';
import { LaunchDarklyService, LocationFeatureFlagRequest } from './LaunchDarklyTypes';

/**
 * Reads the clientSideId from config service and initializes LDProvider with the AsyncProviderConfig at the app entry point prior to rendering,
 * to ensure flags and the client are ready at the beginning of the app.
 *
 * The identification of a user can be useful in future contexts related to analytics by using the custom attributes as 'eid'.
 *
 * Custom attributes defines the target rules, ex: enable/disable the flags by location, end, eid, webEnvironment, etc.
 *
 * flags: If specified, the React SDK will only subscribe for updates to these flags. When this property is unspecified, the React SDK subscribes to all flags.
 * This property is optional.
 * **/
export const useLdProvider = (): LaunchDarklyService => {
  const { eid } = useAuthStore();
  const {
    secrets: { reservationsDevLaunchDarklyKey },
  } = useContext(SecretsContext);

  const client = useLDClient();

  const flags: LDFlagSet = getDefaultFeatureFlagsValues();

  const createLdProvider = useCallback(async (): Promise<({ children }: { children: ReactNode }) => JSX.Element> => {
    const handleLaunchDarklyError = (error?: unknown): void => {
      logError(error, 'Failed to initialize Launch Darkly');
    };

    try {
      if (!eid || !reservationsDevLaunchDarklyKey) {
        handleLaunchDarklyError(
          new Error('Failed to initialize launchDarkly, missing essential data', {
            cause: { eid, hasToken: Boolean(reservationsDevLaunchDarklyKey) },
          })
        );
      } else {
        logDebug('initializing launch darkly with asyncWithLDProvider');

        return asyncWithLDProvider({
          clientSideID: reservationsDevLaunchDarklyKey,
          context: {
            kind: 'user',
            key: eid.toLowerCase(),
            custom: {
              eid: eid.toLowerCase(),
            },
          },
          flags: flags,
          options: {
            streaming: !isDevCypressRun(),
            bootstrap: !isDevCypressRun() ? flags : undefined,
          },
        });
      }
    } catch (error) {
      handleLaunchDarklyError(error);
    }

    return ({ children }) => {
      return <>{children}</>;
    };
  }, [flags, eid, reservationsDevLaunchDarklyKey]);

  const identifyUser = useCallback(
    ({ peoplesoftId, groupBranchId, countryCode }: LocationFeatureFlagRequest): Promise<LDFlagSet> | undefined => {
      const { device } = parser(navigator.userAgent);
      return client?.identify({
        key: eid.toLowerCase(),
        custom: {
          eid: eid.toLowerCase(),
          locationPsId: peoplesoftId,
          countryIso3: countryCode,
          groupLegacyCode: groupBranchId,
          device: device?.model?.toUpperCase() || '',
        },
      });
    },
    [client, eid]
  );

  return { createLdProvider, identifyUser };
};
