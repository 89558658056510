import { FC, useMemo } from 'react';
import { getLicenseIssuerLabel, ISSUER_REGION_COUNTRIES } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { CorporateCountry } from 'utils/countryUtils';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { SelectField } from 'components/shared/forms/SelectField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { useTranslations } from 'components/shared/i18n';
import { useGetLegalIdentificationTypes } from 'services/renter/renterReferenceQueries';
import { DriverFormFields } from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { transformLegalIdTypes } from 'components/shared/uiModels/driver/driverTransformer';
import { DRIVERS_LICENSE_LEGAL_ID_TYPE } from 'utils/constants';

type SubSectionProps = {
  isDisabled?: boolean;
};

type LicenseIssuerFieldProps = SubSectionProps & {
  licenseCountry: CorporateCountry;
};

export const LicenseIssuerField: FC<LicenseIssuerFieldProps> = ({ licenseCountry, isDisabled }) => {
  const { t } = useTranslations();
  const licenseIssuerLabel = useMemo(() => getLicenseIssuerLabel(licenseCountry), [licenseCountry]);
  const issuerDropdown = useMemo(
    () => licenseCountry && ISSUER_REGION_COUNTRIES.includes(licenseCountry),
    [licenseCountry]
  );

  return (
    <GridItem sm={6}>
      {issuerDropdown ? (
        <CountrySubDivisionSelectField
          name={DriverFormFields.LicenseIssuerRegion}
          label={t(licenseIssuerLabel)}
          data-testid={'licenseIssuerRegion'}
          country={licenseCountry}
          disabled={isDisabled}
          required
        />
      ) : (
        <FormTextField
          name={DriverFormFields.LicenseIssuerAuth}
          label={t(licenseIssuerLabel)}
          data-testid={'licenseIssuerField'}
          required
          disabled={
            licenseCountry === CorporateCountry.Spain || licenseCountry === CorporateCountry.GreatBritain || isDisabled
          }
        />
      )}
    </GridItem>
  );
};

export const IdentificationNumberField: FC<SubSectionProps> = ({ isDisabled = false }) => {
  const { t } = useTranslations();

  return (
    <GridItem sm={4}>
      <FormTextField
        name={DriverFormFields.IdentificationNumber}
        label={t('driver.identificationNumber')}
        data-testid={'idNumber'}
        disabled={isDisabled}
        required
      />
    </GridItem>
  );
};

export const LicenseDVLAField: FC = () => {
  const { t } = useTranslations();

  return (
    <GridItem sm={4}>
      <FormTextField
        name={DriverFormFields.LicenseDVLA}
        label={t('driver.dvla')}
        data-testid={'licenseDVLA'}
        disabled
        value={t('driver.licensePresented')}
      />
    </GridItem>
  );
};

export const PlaceOfBirthField: FC<SubSectionProps> = ({ isDisabled = false }) => {
  const { t } = useTranslations();

  return (
    <GridItem sm={6}>
      <FormTextField
        name={DriverFormFields.PlaceOfBirth}
        label={t('driver.placeOfBirth')}
        data-testid={'placeOfBirth'}
        disabled={isDisabled}
        required
      />
    </GridItem>
  );
};

export const SpainSubSection: FC<SubSectionProps> = ({ isDisabled = false }) => {
  const { t } = useTranslations();
  const { data } = useGetLegalIdentificationTypes();
  const legalIdTypeOptions = useMemo(() => {
    const idTypeOptions = data?.filter((x) => x.code !== DRIVERS_LICENSE_LEGAL_ID_TYPE);
    return idTypeOptions && transformLegalIdTypes(idTypeOptions);
  }, [data]);

  return (
    <GridContainer data-testid={'idSectionSpain'}>
      <GridItem sm={12}>
        <h3>{t('driver.identification')}</h3>
      </GridItem>
      <GridItem sm={6}>
        <CountrySelectField
          name={DriverFormFields.IdIssuingCountry}
          data-testid={'idIssuingCountry'}
          label={t('driver.issuingCountry')}
          disabled={isDisabled}
        />
      </GridItem>
      <GridItem sm={6}>
        <SelectField
          name={DriverFormFields.IdType}
          label={t('driver.idType')}
          data-testid={'idType'}
          disabled={isDisabled}
          options={legalIdTypeOptions ?? []}
        />
      </GridItem>
      <GridItem sm={6}>
        <FormTextField
          name={DriverFormFields.IdPassportNumber}
          label={t('driver.idPassport')}
          data-testid={'idPassportNumber'}
          type='text'
          disabled={isDisabled}
          inputProps={{ maxLength: 15 }}
        />
      </GridItem>
      <GridItem sm={6}>
        <DatePickerField
          name={DriverFormFields.IdExpirationDate}
          label={t('driver.expDate')}
          data-testid={'idExpirationDate'}
          disabled={isDisabled}
        />
      </GridItem>
    </GridContainer>
  );
};
