import { styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import CircleIcon from '@mui/icons-material/Circle';

export const InnerStepContainer = styled('div')<{ first?: boolean; last?: boolean }>((props) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',

  '.top-progress-line': {
    backgroundColor: props.first ? 'transparent' : 'black',
  },
  '.bottom-progress-line': {
    backgroundColor: props.last ? 'transparent' : 'black',
  },
}));

export const StepContent = styled('div')`
  flex: 2 1 auto;
  overflow: hidden;
`;

export const StepIndicatorContainer = styled('div')`
  display: flex;
  margin-right: ${ehiTheme.spacing(3)};
  flex-direction: column;
  align-items: center;
  color: ${ehiTheme.palette.secondary.light};
`;

export const StyledStepIcon = styled(CircleIcon)`
  align-content: center;
  width: 12px;
  height: 12px;
`;

export const ProgressLine = styled('div')<{ isVisible: boolean }>((props) => ({
  width: '2px',
  flex: '2 1 auto',
  backgroundColor: props.isVisible ? ehiTheme.palette.divider : 'transparent',
}));
