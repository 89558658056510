import { Rate } from 'services/booking/bookingTypes';
import { RenterWarning } from 'services/renter/driverProfile/driverProfileTypes';

export enum RenterTypes {
  NoProfile = 'NO_PROFILE',
  DriverProfile = 'DRIVER_PROFILE',
  TransactionalProfile = 'TRANSACTIONAL_PROFILE',
}

export interface Address {
  lines: string[];
  city: string;
  countrySubdivisionCode?: string;
  countryCode?: string;
  postalCode: string;
}

export interface Phone {
  number?: string;
  extension?: string;
  type?: string;
  typeUrn?: string;
  priority?: number;
  country?: string;
}
export interface Contact {
  // Note: contact name might be different from renter in No_profile scenario
  name?: string;
}

export interface License {
  country?: string;
  countrySubdivision?: string;
  expirationDate?: string;
  lastFourDigits?: string;
  number?: string;
  type?: string;
  issueDate?: string;
  issuingAuthority?: string;
}

export interface LegalIdentification {
  number?: string;
  country?: string;
  expirationDate?: string;
  type?: string;
}

export interface DriverData {
  type?: string;
  creationDate?: string;
  firstName?: string;
  lastName?: string;
  age?: number;
  address?: Address;
  contact?: Contact;
  primaryPhone?: Phone;
  phoneNumbers?: Phone[];
  email?: string;
  urn?: string;
  license?: License;
  legalIdentification?: LegalIdentification;
  dob?: string;
  placeOfBirth?: string;
  loyaltyType?: 'ALAMO_INSIDERS' | 'ENTERPRISE_PLUS' | 'EMERALD_CLUB' | 'ONE_CLUB';
  loyaltyNumber?: string;
  loyaltyProgram?: { urn?: string; name?: string };
  paymentMethods?: PaymentMethod[];
  renterWarning?: RenterWarning;
  driverFees?: Rate;
  additionalDriverIndex?: number;
}

export interface PaymentMethod {
  alias?: string;
  cardBrand?: string;
  cardNumberFirstSix?: string;
  cardNumberLastFour?: string;
  expiration?: string;
  isDefault?: boolean;
  name?: string;
  paymentServiceToken?: string;
  referenceId?: string;
  type?: string;
  wasVerifiedAtRental?: boolean;
}

export interface Option {
  label?: string;
  value?: string;
}
