import { FunctionComponent } from 'react';
import { ProgressLine, StepIndicatorContainer, StyledStepIcon } from 'components/notes/step/Step.styles';

export const StepIndicator: FunctionComponent<{
  first?: boolean;
  last?: boolean;
}> = ({ first, last }) => {
  return (
    <StepIndicatorContainer>
      <ProgressLine isVisible={!first} />
      <StyledStepIcon color={'disabled'} />
      <ProgressLine isVisible={!last} />
    </StepIndicatorContainer>
  );
};
