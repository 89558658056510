import { useCallback } from 'react';
import {
  AdditionalInfoAccountType,
  SaveAdditionalInfoHookReturn,
  SaveRequestsTypes,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { BusinessPayer, EditorBusinessPayer, RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource, updateBusinessPayers } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { BillingAccountType } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { generateUrnForBillingAccount, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { validateAdditionalInformation } from 'services/businessAccount/businessAccountService';
import { RentalStage } from 'services/businessAccount/businessAccountTypes';
import { ACCOUNT_TYPE_NEGOTIATED } from 'utils/rateAndBillingUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { EMPTY_VALUE } from 'utils/constants';
import { ErrorOption, FieldPath, FieldValues } from 'react-hook-form';
import { safelyCatchError } from 'utils/errorUtils';

export const useSaveAdditionalInformation = (): SaveAdditionalInfoHookReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? EMPTY_VALUE;

  const saveAdditionalInfo = useCallback(
    async (
      saveAdditionalInfo: SaveRequestsTypes,
      setError: (
        name: FieldPath<FieldValues> | `root.${string}` | 'root',
        error: ErrorOption,
        options?: { shouldFocus: boolean }
      ) => void
    ): Promise<boolean> => {
      const validateInputFieldsPayload = {
        ignoreMissingRequiredFields: true,
        currentRentalStage: RentalStage.RESERVATION,
        additionalInformationValues:
          saveAdditionalInfo.additionalInformation.length > 0
            ? saveAdditionalInfo.additionalInformation.map((info) => {
                return {
                  additionalInformation: info.fieldId,
                  value: info.value,
                };
              })
            : [],
      };
      try {
        const { isValid, validationWarnings } = await validateAdditionalInformation(
          saveAdditionalInfo.accountNumber,
          validateInputFieldsPayload
        );
        if (isValid) {
          if (saveAdditionalInfo.type === AdditionalInfoAccountType.RATE_SOURCE) {
            const rateSourceRequestBody: RateSource = {
              type: ACCOUNT_TYPE_NEGOTIATED,
              account: saveAdditionalInfo.account,
              additionalInformation: saveAdditionalInfo.additionalInformation,
            };

            const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
            if (errors) {
              await showAlert({ responseMessages: errors });
            }

            // If call is successful, passes boolean so the additional information modal closes
            return !errors;
          } else if (saveAdditionalInfo.type === AdditionalInfoAccountType.BILL_TO) {
            const billToRequestBody: EditorBusinessPayer[] = [
              {
                type: BillingAccountType.BILLING_ACCOUNT,
                billingAccount: generateUrnForBillingAccount(
                  saveAdditionalInfo.accountNumber,
                  saveAdditionalInfo.billingNumber,
                  defaultEhiDatabase
                ),
                additionalInformation: saveAdditionalInfo.additionalInformation,
              } as BusinessPayer,
            ];

            const { errors } = await updateAndRefresh(async () =>
              updateBusinessPayers(bookingEditorId, billToRequestBody)
            );

            if (errors) {
              await showAlert({ responseMessages: errors });
            }

            // If call is successful, passes boolean so the additional information modal closes
            return !errors;
          }
        } else {
          validationWarnings?.forEach((message) => {
            message.paths?.forEach((path) => {
              const issue = saveAdditionalInfo.additionalInformation.find((info) => path === info.fieldId);
              issue?.fieldId &&
                setError(`${saveAdditionalInfo.accountNumber}_${parseUrn(issue.fieldId)}`, {
                  message: message.localizedMessage ?? EMPTY_VALUE,
                });
            });
          });
        }

        return isValid;
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        await showAlert({ description: ehiErrorsResponse.errors?.[0].localizedMessage ?? EMPTY_VALUE });
        return false;
      }
    },
    [updateAndRefresh, bookingEditorId, showAlert, defaultEhiDatabase]
  );

  return {
    saveAdditionalInfo,
  };
};
