import { ehiTheme } from '@ehi/ui';
import { Box, Fab, styled } from '@mui/material';

export const ButtonBox = styled(Box)`
  display: flex;
  padding: ${ehiTheme.spacing(5)};
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${ehiTheme.spacing(2)};
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledAddNoteButton = styled(Fab)`
  display: flex;
  padding: ${ehiTheme.spacing(1.5, 2.5, 1.5, 1.5)};
  align-items: center;
  gap: ${ehiTheme.spacing(1)};
  border-radius: 10000000000px;
  color: ${ehiTheme.palette.primary.contrastText};
  background: var(--primary-text-dark, linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), #4d789d);

  /* elevation/06dp */
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 800px) {
    // This accounts for the global nav bar on tablet
    margin-bottom: ${ehiTheme.spacing(9)};
  }
`;
