import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectIsReservation } from 'redux/selectors/bookingEditor';
import { useLocation } from 'react-router-dom';
import { HashPaths, RouterPaths } from 'app/router/RouterPaths';

export type NotesHook = {
  isFullRes: boolean;
  isNotesPage: boolean;
};

export const useNotes = (): NotesHook => {
  const isReservation = useAppSelector(selectIsReservation);
  const location = useLocation();

  const isFullRes = useMemo(() => {
    return isReservation && location.hash !== HashPaths.QuickRes;
  }, [isReservation, location]);

  const isNotesPage = useMemo(() => {
    return location.pathname.includes(RouterPaths.Notes);
  }, [location]);

  return {
    isFullRes,
    isNotesPage,
  };
};
