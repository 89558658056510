import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { ehiTheme } from '@ehi/ui';

const gridItemProps = {
  item: true,
  xs: 12,
  sm: 4,
};

export const HistoryGridItem = styled(Grid)`
  padding-bottom: ${ehiTheme.spacing(2)};,
`;
HistoryGridItem.defaultProps = gridItemProps;

export const InfoIcon = styled(InfoOutlinedIcon)`
  font-size: small;
  padding-left: ${ehiTheme.spacing(3)};
  color: #555759;
`;

export const HistoryCaption = styled(Caption2)`
  color: #0000008a;
`;
