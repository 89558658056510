import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Body1, ehiTheme, H4 } from '@ehi/ui';
import { NoHistoryTextBox } from 'components/notes/historyTab/paymentHistory/noPayments/NoPayments.styles';

export const NoPayments: FC = () => {
  const { t } = useTranslations();

  return (
    <NoHistoryTextBox>
      <H4 color={ehiTheme.palette.text.disabled} data-testid={'noNotesTitle'}>
        {t('notes.history.noPaymentsAdded')}
      </H4>
      <Body1 data-testid={'noNotesDescription'} color='textSecondary'>
        {t('notes.history.paymentHistoryDefaultText')}
      </Body1>
    </NoHistoryTextBox>
  );
};
