import { Box } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import { ConsentRadioGroup } from 'components/flexFlow/driver/consent/ConsentRadioGroup';
import { FormProvider, useForm } from 'react-hook-form';
import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Section } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { ConsentProps } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { consentInitialValues } from 'components/flexFlow/driver/consent/consentUtils';

export const ConsentSection: FC<ConsentProps> = ({ driverDetails }) => {
  const { t } = useTranslations();

  const formMethods = useForm({
    defaultValues: consentInitialValues,
  });

  return (
    <Section data-testid={'consentSection'}>
      <FormProvider {...formMethods}>
        <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(2)}>
          <DividerWithMargin
            style={{
              borderColor: ehiTheme.palette.divider,
              borderBottomWidth: 1,
            }}
          />
          <Body1 data-testid={'consent-question'}>{t('consent.question')}</Body1>
          <ConsentRadioGroup driverDetails={driverDetails} />
        </Box>
      </FormProvider>
    </Section>
  );
};
