import { FC, Suspense, useMemo } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { AppConfigGuard } from 'components/shared/appConfig/AppConfigGuard';
import { AuthGuard } from 'components/shared/auth/AuthGuard';
import { SecretsManagerGuard } from 'components/shared/secrets/SecretsManagerGaurd';
import { LoggerManager } from 'components/shared/logger/LoggerManager';
import { LaunchDarklyProvider } from 'components/shared/launchDarkly/LaunchDarklyProvider';
import { LocationGuard } from 'components/locationGuard/LocationGuard';
import PendoInitializer from 'components/shared/pendo/PendoInitializer';
import { AttachmentContextProvider } from '@ehi/support';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { handleError } from 'utils/errorUtils';
import { getAppRoutes } from 'app/router/RouteBuilder';
import { AxiosConfigGuard } from 'components/shared/axios/AxiosConfigGuard';
import { AlertProvider } from 'components/shared/alert/AlertContext';
import { ResSummaryDrawerProvider } from 'context/resSummary/ResSummaryDrawerContext';
import { HelmetProvider } from 'react-helmet-async';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { SaveActionProvider } from 'context/saveAction/SaveActionContext';
import { useTranslations } from 'components/shared/i18n';
import { MapsProvider } from 'components/shared/google/MapsProvider';
import { ResSnackbarProvider } from 'context/resSnackbar/ResSnackbarContext';
import { isDevCypressRun } from 'utils/buildInfoUtil';

const RouteDependentComponents: FC = () => {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <AppConfigGuard>
        <AuthGuard>
          <AxiosConfigGuard>
            <SecretsManagerGuard>
              <LoggerManager>
                <LaunchDarklyProvider>
                  <LocationGuard>
                    {!isDevCypressRun() && <PendoInitializer />}
                    <AttachmentContextProvider>
                      <HelmetProvider>
                        <AlertProvider>
                          <ResSnackbarProvider>
                            <ResSummaryDrawerProvider>
                              <SaveActionProvider>
                                <MapsProvider>
                                  {/* Outlet is where all the route components *actually* get rendered */}
                                  <Outlet />
                                </MapsProvider>
                              </SaveActionProvider>
                            </ResSummaryDrawerProvider>
                          </ResSnackbarProvider>
                        </AlertProvider>
                      </HelmetProvider>
                    </AttachmentContextProvider>
                  </LocationGuard>
                </LaunchDarklyProvider>
              </LoggerManager>
            </SecretsManagerGuard>
          </AxiosConfigGuard>
        </AuthGuard>
      </AppConfigGuard>
    </Suspense>
  );
};
export const AppRouterProvider: FC = () => {
  const { t } = useTranslations();
  const router = useMemo(() => {
    return createBrowserRouter([
      {
        path: '/',
        element: <RouteDependentComponents />,
        errorElement: <ErrorBoundary FallbackComponent={ErrorWrapper} onError={handleError} />,
        children: getAppRoutes(t),
      },
    ]);
  }, [t]);

  return <RouterProvider router={router} />;
};
