import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import './components/shared/i18n/i18n';
import App from './app/App';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { isDevCypressRun } from 'utils/buildInfoUtil';

// expose store when run in Cypress
if (isDevCypressRun()) {
  (window as any).store = store;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);
