import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  DriverFormFields,
  DriverFormValues,
  DriverType,
  PhoneFieldNames,
} from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { CORPORATE_COUNTRIES, CorporateCountry } from 'utils/countryUtils';
import { DateTime } from 'luxon';
import { EMPTY_VALUE } from 'utils/constants';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { parseUrn } from 'utils/urnUtils';
import { TranslationKey } from 'components/shared/i18n/i18n';
import { AnyObject, StringSchema } from 'yup';
import { AdditionalDriver } from 'services/booking/bookingTypes';

export const driverFormValidationSchema = (t: TFunction<'translation'>) =>
  Yup.object().shape({
    lastName: Yup.string()
      .min(2, t('validation.minFieldLength', { fieldLength: 2 }))
      .required(t('validation.requiredField'))
      .matches(/^(\D*)$/i, t('validation.invalidLastName')),
    firstName: Yup.string()
      .matches(/^(\D*)$/i, t('validation.invalidFirstName'))
      .min(2, t('validation.minFieldLength', { fieldLength: 2 }))
      .required(t('validation.requiredField'))
      .matches(/^(\D*)$/i, t('validation.invalidLastName')),
    streetAddress1: Yup.string().when(DriverFormFields.DriverCountry, {
      is: (driverCountry: string) => !!driverCountry,
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),
    streetAddress2: Yup.string(),
    city: Yup.string().when(DriverFormFields.DriverCountry, {
      is: (driverCountry: string) => !!driverCountry,
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),
    subRegion: Yup.string().when(DriverFormFields.DriverCountry, generateAddressValidation(CORPORATE_COUNTRIES, t)),
    postalCode: Yup.string().when(DriverFormFields.DriverCountry, {
      is: (driverCountry: string) => !!driverCountry,
      then: () => Yup.string().required(t('validation.requiredField')),
      otherwise: () => Yup.string(),
    }),

    // LICENSE SECTION
    licenseCountry: Yup.string(),
    licenseNumber: Yup.string().when(DriverFormFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry.length,
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    licenseIssuerRegion: Yup.string().when(DriverFormFields.LicenseCountry, {
      is: (licenseCountry: string) =>
        licenseCountry.length > 0 && ISSUER_REGION_COUNTRIES.find((value) => value === licenseCountry),
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    licenseIssuerAuth: Yup.string().when(DriverFormFields.LicenseCountry, {
      is: (licenseCountry: string) =>
        licenseCountry.length > 0 && !ISSUER_REGION_COUNTRIES.find((value) => value === licenseCountry),
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    licenseExpirationDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .test(DriverFormFields.LicenseExpirationDate, t('validation.requiredField'), (value, context) => {
        const { licenseCountry } = context.parent;
        if (
          licenseCountry &&
          (licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada)
        ) {
          return !!value;
        } else {
          return true;
        }
      })
      .test(DriverFormFields.LicenseExpirationDate, t('validation.expOrIssueDateRequired'), (value, context) => {
        const { licenseCountry, licenseIssuedDate } = context.parent;
        if (
          licenseCountry &&
          !licenseIssuedDate &&
          !(licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada)
        ) {
          return !!value;
        } else {
          return true;
        }
      }),
    licenseIssuedDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .max(DateTime.now().startOf('day').toJSDate(), t('validation.pastDateRequired'))
      .test(DriverFormFields.LicenseIssuedDate, t('validation.expOrIssueDateRequired'), (value, context) => {
        const { licenseCountry, licenseExpirationDate } = context.parent;
        if (
          licenseCountry &&
          !licenseExpirationDate &&
          !(licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada)
        ) {
          return !!value;
        } else {
          return true;
        }
      }),
    dateOfBirth: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(DriverFormFields.LicenseCountry, {
        is: (licenseCountry: string) => licenseCountry,
        then: () =>
          Yup.date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required(t('validation.requiredField'))
            .typeError(t('validation.invalidDateFormat'))
            .max(DateTime.now().startOf('day').toJSDate(), t('validation.pastDateRequired')),
      }),
    identificationNumber: Yup.string().when(DriverFormFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry && licenseCountry === CorporateCountry.Germany,
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    placeOfBirth: Yup.string().when(DriverFormFields.LicenseCountry, {
      is: (licenseCountry: string) => licenseCountry && licenseCountry === CorporateCountry.France,
      then: () => Yup.string().required(t('validation.requiredField')),
    }),
    idExpirationDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat')),

    // CONTACT SECTION
    phone: Yup.array().of(
      Yup.object().shape({
        number: Yup.string()
          .matches(/^[0-9]*$/, t('validation.positiveNumber'))
          .test('length', t('validation.phoneNumberValidation'), (val) => (val && val.length == 10) || !val),
        type: Yup.string().when(PhoneFieldNames.Number, {
          is: (number: string) => number,
          then: () => Yup.string().required(t('validation.requiredField')),
        }),
        countryCode: Yup.string().when(PhoneFieldNames.Number, {
          is: (number: string) => number,
          then: () => Yup.string().required(t('validation.requiredField')),
        }),
      })
    ),
    email: Yup.string().email(t('validation.invalidEmail')),
  });

export const driverFormInitialValues = (driver: DriverData | undefined): DriverFormValues => {
  return {
    [DriverFormFields.DriverType]: getDriverType(driver),
    [DriverFormFields.LastName]: driver?.lastName ?? EMPTY_VALUE,
    [DriverFormFields.FirstName]: driver?.firstName ?? EMPTY_VALUE,
    [DriverFormFields.Age]: (driver?.age && getAgeOption(driver.age)) ?? EMPTY_VALUE,
    [DriverFormFields.DriverCountry]: driver?.address?.countryCode ?? EMPTY_VALUE,
    [DriverFormFields.StreetAddress1]: driver?.address?.lines[0] ?? EMPTY_VALUE,
    [DriverFormFields.StreetAddress2]: driver?.address?.lines[1] ?? EMPTY_VALUE,
    [DriverFormFields.City]: driver?.address?.city ?? EMPTY_VALUE,
    [DriverFormFields.SubRegion]: driver?.address?.countrySubdivisionCode ?? EMPTY_VALUE,
    [DriverFormFields.PostalCode]: driver?.address?.postalCode ?? EMPTY_VALUE,
    [DriverFormFields.LicenseCountry]: driver?.license?.country ? parseUrn(driver?.license?.country) : EMPTY_VALUE,
    [DriverFormFields.LicenseNumber]: driver?.license?.number ? driver?.license?.number : EMPTY_VALUE,
    [DriverFormFields.LicenseIssuerRegion]: driver?.license?.countrySubdivision
      ? parseUrn(driver?.license?.countrySubdivision)
      : EMPTY_VALUE,
    [DriverFormFields.LicenseIssuerAuth]: driver?.license?.issuingAuthority
      ? driver?.license?.issuingAuthority
      : EMPTY_VALUE,
    [DriverFormFields.LicenseExpirationDate]: driver?.license?.expirationDate
      ? DateTime.fromISO(driver?.license?.expirationDate)
      : EMPTY_VALUE,
    [DriverFormFields.LicenseIssuedDate]: driver?.license?.issueDate
      ? DateTime.fromISO(driver?.license?.issueDate)
      : EMPTY_VALUE,
    [DriverFormFields.IdentificationNumber]: driver?.legalIdentification?.number
      ? driver?.legalIdentification?.number
      : EMPTY_VALUE,
    [DriverFormFields.LicenseDVLA]: undefined,

    [DriverFormFields.DOB]: driver?.dob ? DateTime.fromISO(driver?.dob) : EMPTY_VALUE,
    [DriverFormFields.PlaceOfBirth]: driver?.placeOfBirth ?? undefined,
    [DriverFormFields.IdIssuingCountry]: parseUrn(driver?.legalIdentification?.country)
      ? parseUrn(driver?.legalIdentification?.country)
      : EMPTY_VALUE,
    [DriverFormFields.IdExpirationDate]: driver?.legalIdentification?.expirationDate
      ? DateTime.fromISO(driver?.legalIdentification?.expirationDate)
      : EMPTY_VALUE,
    [DriverFormFields.IdType]: parseUrn(driver?.legalIdentification?.type) ?? EMPTY_VALUE,
    [DriverFormFields.IdPassportNumber]: driver?.legalIdentification?.number
      ? driver?.legalIdentification?.number
      : undefined,
    [DriverFormFields.Phone]:
      driver?.phoneNumbers && driver?.phoneNumbers.length > 0
        ? driver?.phoneNumbers?.map((phone) => ({
            type: phone?.typeUrn ?? EMPTY_VALUE,
            number: phone?.number ?? EMPTY_VALUE,
            countryCode: phone?.country ?? EMPTY_VALUE,
            priority: phone?.priority ?? 1,
          }))
        : [{ type: EMPTY_VALUE, number: EMPTY_VALUE, countryCode: EMPTY_VALUE, priority: 1 }],
    [DriverFormFields.Email]: driver?.email ?? EMPTY_VALUE,
  };
};

export const FRANCE_LICENSE_FIELDS = [DriverFormFields.PlaceOfBirth];
export const GERMANY_LICENSE_FIELDS = [DriverFormFields.IdentificationNumber];
export const SPAIN_LICENSE_FIELDS = [
  DriverFormFields.IdIssuingCountry,
  DriverFormFields.IdType,
  DriverFormFields.IdPassportNumber,
  DriverFormFields.IdExpirationDate,
];
export const ISSUER_REGION_COUNTRIES = [
  CorporateCountry.Canada,
  CorporateCountry.France,
  CorporateCountry.UnitedStates,
];
export const DVLA_LICENSE_VALUE = 'DVLA';
export const PHONE_COUNT_MAX = 4;

enum AgeRange {
  RANGE_18_20 = '18-20',
  RANGE_21_24 = '21-24',
  RANGE_25_PLUS = '25+',
}

enum AgeRangeValues {
  RANGE_18_20 = 18,
  RANGE_21_24 = 21,
  RANGE_25_PLUS = 25,
}

export const AGE_OPTIONS = [
  { label: AgeRange.RANGE_18_20, value: AgeRangeValues.RANGE_18_20 },
  { label: AgeRange.RANGE_21_24, value: AgeRangeValues.RANGE_21_24 },
  { label: AgeRange.RANGE_25_PLUS, value: AgeRangeValues.RANGE_25_PLUS },
];

const getDriverType = (driver: DriverData | undefined): DriverType | undefined => {
  if (driver?.type === RenterTypes.DriverProfile) {
    if (driver.loyaltyNumber) {
      return DriverType.LOYALTY_DRIVER_PROFILE;
    }
    if (driver.renterWarning) {
      return DriverType.DRIVER_PROFILE_DNR;
    }
    return DriverType.DRIVER_PROFILE;
  }

  return undefined;
};

const provinceCountries = [CorporateCountry.Canada, CorporateCountry.France, CorporateCountry.Spain];
const countyCountries = [CorporateCountry.Germany, CorporateCountry.Ireland, CorporateCountry.GreatBritain];
export const getRegionLabel = (countryCode: CorporateCountry): TranslationKey | undefined => {
  if (provinceCountries.includes(countryCode)) {
    return 'location.province';
  } else if (countyCountries.includes(countryCode)) {
    return 'location.county';
  } else if (countryCode === CorporateCountry.UnitedStates) {
    return 'location.state';
  } else {
    return undefined;
  }
};

const generateAddressValidation = (
  countries: string[],
  t: TFunction<'translation'>
): {
  is: (driverCountry: string) => boolean;
  then: () => StringSchema<NonNullable<string | undefined>, AnyObject, undefined, ''>;
  otherwise: () => StringSchema<string | undefined, AnyObject, undefined, ''>;
} => {
  return {
    is: (driverCountry: string) => countries.includes(driverCountry),
    then: () => Yup.string().required(t('validation.requiredField')),
    otherwise: () => Yup.string(),
  };
};

export const getAgeOption = (age: number): number | undefined => {
  if (age >= 18 && age <= 20) {
    return AgeRangeValues.RANGE_18_20;
  } else if (age >= 21 && age <= 24) {
    return AgeRangeValues.RANGE_21_24;
  } else if (age >= 25) {
    return AgeRangeValues.RANGE_25_PLUS;
  } else {
    return undefined;
  }
};

export const getLicenseIssuerLabel = (countryCode: CorporateCountry | ''): TranslationKey => {
  if (countryCode === CorporateCountry.Canada || countryCode === CorporateCountry.France) {
    return 'driver.issuingProvince';
  } else if (countryCode === CorporateCountry.UnitedStates) {
    return 'location.subRegionLabel';
  } else if (countryCode === CorporateCountry.Germany) {
    return 'driver.issuingAuth';
  } else if (countryCode === CorporateCountry.Spain) {
    return 'driver.issuedIn';
  } else {
    return 'driver.issuingAuth';
  }
};

export const resetFieldsClearErrors = (
  fields: string[],
  resetField: (field: string) => void,
  clearErrors: (field: string) => void
): void => {
  fields.forEach((field: string) => {
    resetField(field);
    clearErrors(field);
  });
};

export const isValidFullProfile = (values: DriverFormValues): boolean | '' | undefined => {
  const {
    firstName,
    lastName,
    licenseNumber,
    dateOfBirth,
    licenseCountry,
    licenseExpirationDate,
    licenseIssuerRegion,
  } = values;
  const containsFullName = firstName.length > 0 && lastName.length > 0;
  const containsLicense = licenseNumber.length > 0;
  const containsDOB = dateOfBirth && dateOfBirth.isValid;

  let containsLicenseLocationInfo = licenseExpirationDate && licenseExpirationDate.isValid;
  if (containsLicenseLocationInfo) {
    const isCanOrUsa = licenseCountry === CorporateCountry.Canada || licenseCountry === CorporateCountry.UnitedStates;
    if (isCanOrUsa && !!licenseIssuerRegion) {
      containsLicenseLocationInfo = true;
    } else if (licenseCountry.length > 0 && !isCanOrUsa) {
      containsLicenseLocationInfo = true;
    }
  }

  return containsFullName && containsLicense && containsDOB && containsLicenseLocationInfo;
};

export const parseContactName = (fullName: string): { given: string; surname: string } => {
  const [given, ...surnameParts] = fullName?.trim().split(' ').filter(Boolean) || [];
  return { given: given ?? '', surname: surnameParts.join(' ') || '' };
};
export const REMOVE = 'remove';
export const ADD = 'add';
export const SELECTION_OPTION = 'selectedOption';
export const RETAIL = 'RETAIL';
export const ADDITIONAL_DRIVER_SURNAME = 'Driver';
export const ADDITIONAL_DRIVER_GIVEN_NAME = 'Add';

export const getUpdatedAdditionalDrivers = (
  currentAdditionalDrivers: AdditionalDriver[],
  driverProfile: string | undefined
): AdditionalDriver[] => {
  if (driverProfile) {
    return currentAdditionalDrivers.filter((additionalDriver) => additionalDriver.profile !== driverProfile);
  } else {
    const additionalDrivers = [...currentAdditionalDrivers];
    const index = additionalDrivers?.findIndex(
      // Note: given name as Add and surname as Driver are added by editor for additional driver, without driver data
      (item) => item?.name?.given === ADDITIONAL_DRIVER_GIVEN_NAME && item?.name?.surname === ADDITIONAL_DRIVER_SURNAME
    );
    if (index !== -1) {
      additionalDrivers.splice(index, 1);
    }

    return additionalDrivers;
  }
};
