import { EditorIssue, VehicleClassSelection } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import { TranslationKey } from 'components/shared/i18n/i18n';
import { TFunction } from 'i18next';
import { RouteSegments, EMPTY_VALUE } from 'utils/constants';
import { AvailableBookingIssue } from 'hooks/bookingEditor/useRefreshEditor';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { To } from 'react-router-dom';
import { HashPaths, RouterPaths } from 'app/router/RouterPaths';
import { DescriptionWithAction } from 'components/shared/alert/AlertDialogTypes';

export enum VehicleAvailabilityBookingIssues {
  ReservedVehicleNotAvailable = 'BOOK2078',
  PreferredVehicleNotAvailable = 'BOOK2053',
}

export enum BillToBookingIssues {
  BillToInvalidLocation = 'BOOK2082',
  BillToAccountInactive = 'BOOK2083',
}

export enum PeoBookingIssues {
  EquipmentUnavailable = 'BOOK2039',
  ProtectionUnavailable = 'BOOK2034',
}

export const SupportingServiceBookingIssue = 'BOOK1000';
export const InvalidRateProductBookingIssue = 'BOOK2070';
export const VehicleNotValidForYDBookingIssue = 'BOOK2072';
export const RateProductIsModifiedBookingIssue = 'BOOK2077';
export const DriverNotValidForBrandBookingIssue = 'BOOK2080';

export const hasVehicleAvailabilityBookingIssues = (issues: EditorIssue[]): boolean => {
  return issues?.some((issue: EditorIssue) =>
    Object.values(VehicleAvailabilityBookingIssues).find((vehicleIssue) => vehicleIssue === parseUrn(issue.issueCode))
  );
};

export const hasBillToBookingIssues = (issues: EditorIssue[]): boolean => {
  return issues?.some((issue: EditorIssue) =>
    Object.values(BillToBookingIssues).find((billToIssue) => billToIssue === parseUrn(issue.issueCode))
  );
};

export const hasVehicleYoungDriverBookingIssue = (issues: EditorIssue[]): boolean => {
  return !!issues.find((issue: EditorIssue) => parseUrn(issue.issueCode) === VehicleNotValidForYDBookingIssue);
};

export const getVehicleYoungDriverBookingIssue = (t: TFunction, vehicleSipp: string): AvailableBookingIssue => {
  return {
    description: {
      message: t('snackbarMessages.vehicleRemoved', {
        vehicleSipp,
        additionalInfo: t('snackbarMessages.additionalInfo.youngDriver'),
      }),
      routePath: getCorrelatedRoutePathForBookingError(VehicleNotValidForYDBookingIssue),
    },
    bookingIssueCode: VehicleNotValidForYDBookingIssue,
  };
};

export const getVehicleNotAvailableBookingIssue = (
  t: TFunction,
  additionalInfo: TranslationKey,
  vehicleSipp: string,
  issueCode: string
): AvailableBookingIssue | undefined => {
  const vehicleIssueCode = Object.values(VehicleAvailabilityBookingIssues).find((issue) => issue === issueCode);
  if (!vehicleIssueCode) {
    return;
  }
  return {
    description: {
      message: t('snackbarMessages.vehicleRemoved', {
        vehicleSipp,
        additionalInfo,
      }),
      routePath: getCorrelatedRoutePathForBookingError(vehicleIssueCode),
    },
    bookingIssueCode: vehicleIssueCode,
  };
};

export const getAllVehicleNotAvailableBookingIssues = (
  t: TFunction,
  additionalInfo: TranslationKey,
  vehicle: VehicleClassSelection | undefined
): AvailableBookingIssue[] => {
  if (!vehicle) {
    return [];
  }
  return Object.values(VehicleAvailabilityBookingIssues)
    .map((issueCode: VehicleAvailabilityBookingIssues) => {
      if (issueCode === VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable) {
        return getVehicleNotAvailableBookingIssue(
          t,
          additionalInfo,
          vehicle.preferred ? parseUrn(vehicle.preferred) : EMPTY_VALUE,
          VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable
        );
      } else {
        return getVehicleNotAvailableBookingIssue(
          t,
          additionalInfo,
          vehicle.reserved ? parseUrn(vehicle.reserved) : EMPTY_VALUE,
          VehicleAvailabilityBookingIssues.ReservedVehicleNotAvailable
        );
      }
    })
    .filter((bookingIssue: AvailableBookingIssue | undefined) => !!bookingIssue) as AvailableBookingIssue[];
};

export const getAllBillToBookingIssues = (t: TFunction): AvailableBookingIssue[] => {
  return Object.values(BillToBookingIssues)
    .map((issueCode: BillToBookingIssues) => {
      if (issueCode === BillToBookingIssues.BillToInvalidLocation) {
        return {
          description: {
            message: t('bookingIssue.book2082'),
            routePath: getCorrelatedRoutePathForBookingError(BillToBookingIssues.BillToInvalidLocation),
          },
          bookingIssueCode: BillToBookingIssues.BillToInvalidLocation,
        };
      } else {
        return {
          description: {
            message: t('bookingIssue.book2083'),
            routePath: getCorrelatedRoutePathForBookingError(BillToBookingIssues.BillToAccountInactive),
          },
          bookingIssueCode: BillToBookingIssues.BillToAccountInactive,
        };
      }
    })
    .filter((bookingIssue: AvailableBookingIssue | undefined) => !!bookingIssue) as AvailableBookingIssue[];
};

export const hasPeoBookingIssues = (issues: EditorIssue[]): boolean => {
  return issues?.some((issue: EditorIssue) =>
    Object.values(PeoBookingIssues).find((peoIssue) => peoIssue === parseUrn(issue.issueCode))
  );
};

export const getPeoBookingIssues = (
  t: TFunction,
  additionalInfo: TranslationKey,
  equipmentNames: string[],
  protectionNames: string[]
): AvailableBookingIssue[] => {
  const peoAlertMessages = new Array<AvailableBookingIssue>();
  equipmentNames.forEach((equipment) => {
    peoAlertMessages.push({
      description: {
        message: t('snackbarMessages.peoRemoved', { peoName: equipment, additionalInfo: additionalInfo }),
        routePath: getCorrelatedRoutePathForBookingError(PeoBookingIssues.EquipmentUnavailable),
      },
      bookingIssueCode: PeoBookingIssues.EquipmentUnavailable,
    });
  });
  protectionNames.forEach((protection) => {
    peoAlertMessages.push({
      description: {
        message: t('snackbarMessages.peoRemoved', {
          peoName: protection,
          additionalInfo: additionalInfo,
        }),
        routePath: getCorrelatedRoutePathForBookingError(PeoBookingIssues.ProtectionUnavailable),
      },
      bookingIssueCode: PeoBookingIssues.ProtectionUnavailable,
    });
  });

  return peoAlertMessages;
};

export const hasDriverBookingIssue = (issues: EditorIssue[]): boolean => {
  return !!issues.find((issue: EditorIssue) => parseUrn(issue.issueCode) === DriverNotValidForBrandBookingIssue);
};

export const getDriverProfileBookingIssue = (t: TFunction): AvailableBookingIssue => {
  return {
    description: {
      message: t('bookingIssue.book2080'),
      routePath: getCorrelatedRoutePathForBookingError(DriverNotValidForBrandBookingIssue),
    },
    bookingIssueCode: DriverNotValidForBrandBookingIssue,
  };
};

export const getEhiMessagesAsSnackbarMessages = (
  ehiMessages: ResponseMessage[],
  excludedBookingIssues: string[]
): DescriptionWithAction[] => {
  const filteredMessages = ehiMessages.filter((message) => !excludedBookingIssues.includes(message.code));
  return filteredMessages.map((message): DescriptionWithAction | undefined => {
    if (message.supportInformation) {
      return { message: message.supportInformation };
    } else {
      return { message: message.localizedMessage ?? EMPTY_VALUE };
    }
  }) as DescriptionWithAction[];
};

export const getEhiIssuesAsSnackbarMessages = (
  editorIssues: EditorIssue[],
  availableBookingIssues: AvailableBookingIssue[]
): DescriptionWithAction[] => {
  return availableBookingIssues
    .filter((issue) => editorIssues.find((editorIssue) => parseUrn(editorIssue.issueCode) === issue.bookingIssueCode))
    .map((issue) => issue.description);
};

const DRIVER_ISSUE_CODES = ['BOOK2063', 'BOOK2057', 'BOOK2051', 'BOOK2080'];
const WHEN_AND_WHERE_ISSUE_CODES = [
  'BOOK2044',
  'BOOK2045',
  'BOOK2046',
  'BOOK2047',
  'BOOK2048',
  'BOOK2049',
  'BOOK2050',
  'BOOK2075',
];
const VEHICLE_ISSUE_CODES = ['BOOK2052', 'BOOK2053', 'BOOK2072', 'BOOK2078'];
const ADD_ONS_ISSUE_CODES = ['BOOK2034', 'BOOK2035', 'BOOK2036', 'BOOK2039', 'BOOK2040'];
const RATE_AND_BILL_ISSUE_CODES = ['BOOK2082'];
export const CAN_OVERRIDE_ERROR_CODES = new Set([
  'BOOK2049',
  'BOOK2047',
  'BOOK2050',
  'BOOK2052',
  'BOOK2078',
  'BOOK2053',
]);

export const getCorrelatedRoutePathForBookingError = (
  bookingIssueCode: string,
  isModify = false,
  reservation = ''
): To | undefined => {
  const baseUrl = RouteSegments.BASE_URL;
  const modifySegment = isModify ? RouteSegments.MODIFY(reservation) : EMPTY_VALUE;

  if (DRIVER_ISSUE_CODES.includes(bookingIssueCode)) {
    return { pathname: baseUrl + RouterPaths.Driver, hash: HashPaths.AddDriver };
  } else if (WHEN_AND_WHERE_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.WhenAndWhere;
  } else if (VEHICLE_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.Vehicle;
  } else if (ADD_ONS_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.AddOns;
  } else if (RATE_AND_BILL_ISSUE_CODES.includes(bookingIssueCode)) {
    return baseUrl + modifySegment + RouterPaths.RateAndBill;
  }
  return undefined;
};
