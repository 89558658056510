import { FC } from 'react';
import { NoNotes } from 'components/notes/noNotes/NoNotes';
import { useTranslations } from 'components/shared/i18n';
import {
  DateHeader,
  NotesContainer,
  NotesList,
  NotesSection,
  PreferencesSection,
  StyledHeaderText,
} from 'components/notes/notesTab/NotesTab.styles';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import { PreferencesCard } from 'components/notes/notesTab/cards/preferenceCard/PreferencesCard';
import { AddNoteButton } from 'components/notes/addNote/AddNoteButton';
import { NoteCard } from 'components/notes/notesTab/cards/noteCard/NoteCard';
import { FilterBar } from 'components/notes/filterBar/FilterBar';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';

export const PinnedTab: FC = () => {
  const { t } = useTranslations();
  const isReadOnlyFlow = useAppSelector(selectIsReadOnlyFlow);

  const notesData = [
    {
      type: 'note',
      name: 'Longfirstname Longlastname',
      eid: 'E90VWX',
      timeCreated: '1:23PM CST',
      message: 'Customer is having second thoughts about orange',
    },
    {
      type: 'action',
      name: 'Longfirstname Longlastname',
      eid: 'E89STU',
      timeCreated: '10:48 AM CST',
      message: 'Customer requests a orange vehicle',
    },
    {
      type: 'note',
      name: 'Longfirstname Longlastname',
      eid: 'E78PQR',
      timeCreated: '8:37 AM CST',
      message: 'Customer is having second thoughts about green',
    },
    {
      type: 'note',
      name: 'Longfirstname Longlastname',
      eid: 'E67MNO',
      timeCreated: '11:11 PM CST',
      message: 'Customer requests a green vehicle',
    },
    {
      type: 'action',
      name: 'Longfirstname Longlastname',
      eid: 'E56JKL',
      timeCreated: '4:00 PM CST',
      message: 'Customer is having second thoughts about blue',
    },
    {
      type: 'note',
      name: 'Longfirstname Longlastname',
      eid: 'E45JKL',
      timeCreated: '2:23 PM CST',
      message: 'Customer requests a blue vehicle',
    },
    {
      type: 'note',
      name: 'Longfirstname Longlastname',
      eid: 'E34GHI',
      timeCreated: '11:43 AM CST',
      message: 'Customer is having second thoughts about red',
    },
    {
      type: 'note',
      name: 'Longfirstname Longlastname',
      eid: 'E23DEF',
      timeCreated: '10:15 PM CST',
      message: 'Customer requests a red vehicle',
    },
    {
      type: 'action',
      name: 'Longfirstname Longlastname',
      eid: 'E12ABC',
      timeCreated: '5:25 PM CST',
      message: 'Customer is having second thoughts about purple',
    },
    {
      type: 'action',
      name: 'Longfirstname Longlastname',
      eid: 'E12ABC',
      timeCreated: '4:25 PM CST',
      message: 'Customer requests a purple vehicle',
    },
  ];

  return (
    <NotesContainer>
      {notesData.length ? (
        <>
          <FilterBar />
          <NotesList data-testid={'noteList'}>
            <PreferencesSection>
              <DateHeader data-testid='dateHeader-preferences'>
                <StyledHeaderText>Preferences</StyledHeaderText>
                <DividerWithMargin />
              </DateHeader>
              <PreferencesCard />
            </PreferencesSection>
            <NotesSection>
              <DateHeader data-testid='dateHeader-0'>
                <StyledHeaderText>30 Dec, 2024</StyledHeaderText>
                <DividerWithMargin />
              </DateHeader>
              <NoteCard index={0} note={notesData[0]} isPinnedTab={true} />
              <NoteCard index={1} note={notesData[1]} isPinnedTab={true} />
              <NoteCard index={2} note={notesData[2]} isPinnedTab={true} />
            </NotesSection>
            <NotesSection>
              <DateHeader data-testid='dateHeader-1'>
                <StyledHeaderText>29 Dec, 2024</StyledHeaderText>
                <DividerWithMargin />
              </DateHeader>
              <NoteCard index={3} note={notesData[3]} isPinnedTab={true} />
            </NotesSection>
            <NotesSection>
              <DateHeader data-testid='dateHeader-2'>
                <StyledHeaderText>28 Dec, 2024</StyledHeaderText>
                <DividerWithMargin />
              </DateHeader>
              <NoteCard index={4} note={notesData[3]} isPinnedTab={true} />
              <NoteCard index={5} note={notesData[4]} isPinnedTab={true} />
              <NoteCard index={6} note={notesData[5]} isPinnedTab={true} />
              <NoteCard index={7} note={notesData[6]} isPinnedTab={true} />
            </NotesSection>
            <NotesSection>
              <DateHeader data-testid='dateHeader-3'>
                <StyledHeaderText>27 Dec, 2024</StyledHeaderText>
                <DividerWithMargin />
              </DateHeader>
              <NoteCard index={8} note={notesData[7]} isPinnedTab={true} />
              <NoteCard index={9} note={notesData[8]} isPinnedTab={true} />
              <NoteCard index={10} note={notesData[9]} isPinnedTab={true} />
            </NotesSection>
          </NotesList>
        </>
      ) : (
        <NoNotes title={t('notes.noPinned')} description={t('notes.viewNotesAfterPin')} />
      )}
      {!isReadOnlyFlow && <AddNoteButton />}
    </NotesContainer>
  );
};
