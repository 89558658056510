import { FC, useState } from 'react';
import { Caption2, OverlineText } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import {
  EIDChip,
  MessageIcon,
  NoteCardHeader,
  NoteCardHeaderSection1,
  NoteCardHeaderSection2,
  PushpinButton,
  StyledCircleBackground,
  StyledNoteCard,
  StyledPushpinIcon,
  StyledPushPinOutlinedIcon,
} from 'components/notes/notesTab/cards/noteCard/NoteCard.styles';
import { ACTION_NOTE } from 'utils/constants';
import { Step } from 'components/notes/step/Step';
import { CardContainer } from 'components/notes/notesTab/NotesTab.styles';

type NoteCardProps = {
  first?: boolean;
  last?: boolean;
  index: number;
  note: {
    type: string;
    name: string;
    eid: string;
    timeCreated: string;
    message: string;
  };
  // TODO: Remove isPinnedTab once data is available from Rental Diary
  isPinnedTab?: boolean;
};

export const NoteCard: FC<NoteCardProps> = ({ first, last, note, index, isPinnedTab = false }) => {
  // TODO: Enable this component to track pinned/unpinned state once Rental Diary is integrated
  const [pinned, setNotePinned] = useState(isPinnedTab);

  const handleClick: () => void = () => {
    setNotePinned(!pinned);
  };

  const actionCard = note.type === ACTION_NOTE;

  const cardContent = (
    <CardContainer data-testid={`cardContainer-${index}`} isPinnedTab={isPinnedTab}>
      <StyledNoteCard
        variant={'outlined'}
        style={{
          background:
            actionCard || pinned
              ? 'linear-gradient(0deg, rgba(77, 120, 157, 0.08) 0%, rgba(77, 120, 157, 0.08) 100%), #fff'
              : '#fff',
        }}>
        <NoteCardHeader>
          <NoteCardHeaderSection1>
            {!actionCard && (
              <StyledCircleBackground>
                <MessageIcon />
              </StyledCircleBackground>
            )}
            <OverlineText>{note.name}</OverlineText>
            <EIDChip>{note.eid}</EIDChip>
          </NoteCardHeaderSection1>
          <NoteCardHeaderSection2>
            <PushpinButton aria-label='close' onClick={handleClick}>
              {pinned ? <StyledPushpinIcon /> : <StyledPushPinOutlinedIcon />}
            </PushpinButton>
            <Caption2 sx={{ width: '85px' }}>{note.timeCreated}</Caption2>
          </NoteCardHeaderSection2>
        </NoteCardHeader>
        <Body1>{note.message}</Body1>
      </StyledNoteCard>
    </CardContainer>
  );

  return !isPinnedTab ? (
    <Step first={first} last={last}>
      {cardContent}
    </Step>
  ) : (
    cardContent
  );
};
