import { createContext, Dispatch, FC, JSX, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';
import { ResActionButton } from 'context/resActions/ResActionButton';
import { useSaveActionContext } from 'context/saveAction/SaveActionContext';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { saveToReservation } from 'services/booking/bookingService';
import {
  clearPersistedReservationData,
  getLocationHeaderFromUrl,
} from 'components/shared/preprocessor/ReservationSessionHelper';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectBookingEditorIssues,
  selectIsModifyFlow,
  selectIsReadOnlyFlow,
  selectReservationData,
} from 'redux/selectors/bookingEditor';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import NetworkError from 'components/shared/errors/NetworkError';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { RouterPaths } from 'app/router/RouterPaths';
import { useNavigate } from 'react-router-dom';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { safelyCatchError } from 'utils/errorUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { parseUrn } from 'utils/urnUtils';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { useStartReservationSession } from 'components/shared/preprocessor/useStartReservationSession';
import { CAN_OVERRIDE_ERROR_CODES, getCorrelatedRoutePathForBookingError } from 'utils/bookingUtils';
import { bookingIssues } from 'services/booking/bookingIssues';
import {
  DEFAULT_ERROR_TITLE,
  ERROR_ALERT_VARIANT,
  INFO_ALERT_VARIANT,
  SelectedAction,
} from 'components/shared/alert/AlertDialogTypes';
import { useNotes } from 'components/notes/useNotes';

export type ResActionsContextType = {
  setUpdateFloatingButtonAction: Dispatch<SetStateAction<boolean>>;
};

export const ResActionsContext = createContext<ResActionsContextType>({
  setUpdateFloatingButtonAction: () => undefined,
});

export const useResActionsContext = (): ResActionsContextType => {
  const context = useContext(ResActionsContext);

  if (!context) {
    throw Error('ResActionsContext is not initialized');
  }

  return context;
};

type ResActionsProviderProps = {
  label: string;
  icon: JSX.Element;
  children?: ReactNode;
};

export const ResActionsProvider: FC<ResActionsProviderProps> = ({ label, icon, children }) => {
  const { t } = useTranslations();
  const { saveOnUpdate } = useSaveActionContext();
  const { clearEditorSession } = useReservationSessionHelper();
  const { startModifyEditorSession } = useStartReservationSession();
  const { showAlert } = useAlert();
  const { setSnackBarRes } = useResSnackbarContext();
  const navigate = useNavigate();
  const { isNotesPage } = useNotes();

  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const bookingEditorIssues = useAppSelector(selectBookingEditorIssues);
  const reservationData = useAppSelector(selectReservationData);
  const isModifyFlow = useAppSelector(selectIsModifyFlow);
  const isViewFlow = useAppSelector(selectIsReadOnlyFlow);
  const [updateFloatingButtonAction, setUpdateFloatingButtonAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResActionButtonClick = async (): Promise<void> => {
    if (updateFloatingButtonAction) {
      saveOnUpdate();
    } else if (isViewFlow) {
      // clear session data saved in startViewOnlySession before navigating to modify flow
      clearPersistedReservationData();
      await handleNavigatingToModifyFlow();
    } else {
      await saveEditorToReservation();
    }
  };

  const handleNavigatingToModifyFlow = useCallback(async () => {
    const resNumber = parseUrn(reservationData?.reservation);
    setLoading(true);
    const { errors } = await startModifyEditorSession(resNumber);
    if (!errors?.length) {
      navigate(
        {
          pathname: `/res/${resNumber}/modify/${RouterPaths.WhenAndWhere}`,
        },
        {
          replace: true,
        }
      );
      setLoading(false);
    } else {
      await showAlert({
        variant: 'error',
        description: `${t('error.resEditError')}: ${errors[0].localizedMessage}`,
      });
      setLoading(false);
    }
  }, [navigate, reservationData?.reservation, showAlert, startModifyEditorSession, t]);

  const saveEditorToReservation = useCallback(
    async (skipValidation = false) => {
      if (bookingEditorIssues && bookingEditorIssues.length > 0 && !skipValidation) {
        const errorIssues = bookingEditorIssues.filter(
          (issue) => !CAN_OVERRIDE_ERROR_CODES.has(parseUrn(issue.issueCode))
        );
        const warningIssues = bookingEditorIssues.filter((issue) =>
          CAN_OVERRIDE_ERROR_CODES.has(parseUrn(issue.issueCode))
        );

        const issuesToShow = errorIssues.length > 0 ? errorIssues : warningIssues;
        const isError = errorIssues.length > 0;

        const descriptions = issuesToShow.map((issue) => {
          const translationValue = bookingIssues.find(
            (bookingIssue: { code: string }) => bookingIssue.code === parseUrn(issue.issueCode)
          )?.translationKey;

          return {
            message: translationValue ? t(translationValue) : parseUrn(issue.issueCode),
            routePath: getCorrelatedRoutePathForBookingError(
              parseUrn(issue.issueCode),
              isModifyFlow,
              reservationData?.reservation
            ),
          };
        });

        const selectedAction = await showAlert({
          variant: isError ? ERROR_ALERT_VARIANT : INFO_ALERT_VARIANT,
          title: isError ? t(DEFAULT_ERROR_TITLE) : t('common.saveReservation'),
          subtitle: !isError && warningIssues.length > 0 ? t('common.confirmToProceedWithoutTheFollowing') : undefined,
          descriptions,
          navigate,
          primaryActionText: !isError ? t('common.confirm') : t('common.close'),
          secondaryActionText: !isError ? t('common.cancel') : undefined,
        });

        if (selectedAction === SelectedAction.Primary && !isError) {
          await saveEditorToReservation(true);
        }
      } else {
        try {
          const resSnackbarMessage = isModifyFlow ? 'quickReservation.resUpdated' : 'quickReservation.resCreated';
          setLoading(true);
          const { headers } = await saveToReservation(bookingEditorId, { skipValidation: skipValidation });
          const resNum = getLocationHeaderFromUrl(headers?.location);
          setSnackBarRes({
            onViewAction: () => {
              navigate(
                `${RouterPaths.PreProcessor}?${generateSearchParams({
                  [InternalTransactionParams.TransactionType]: TransactionTypes.View,
                  [InternalTransactionParams.Res]: resNum,
                })}`
              );
            },
            message: t(resSnackbarMessage, { resNum }),
            isOpen: true,
          });
          clearEditorSession();
          navigate(RouterPaths.Search);
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        } finally {
          setLoading(false);
        }
      }
    },
    [
      bookingEditorId,
      bookingEditorIssues,
      clearEditorSession,
      isModifyFlow,
      navigate,
      reservationData?.reservation,
      setSnackBarRes,
      showAlert,
      t,
    ]
  );

  return (
    <ResActionsContext.Provider value={{ setUpdateFloatingButtonAction }}>
      <LoadableView
        loadingComponent={<FullScreenSpinner />}
        errorComponent={<NetworkError />}
        state={mapLoadingState(loading, false) ?? LoadingState.SUCCESS}>
        {children}
      </LoadableView>
      {!isNotesPage && (
        <ResActionButton
          label={label}
          icon={icon}
          updateFloatingButtonAction={updateFloatingButtonAction}
          handleButtonClick={handleResActionButtonClick}
        />
      )}
    </ResActionsContext.Provider>
  );
};
