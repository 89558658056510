import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AddNotesFields, AddNotesValues } from 'components/notes/addNote/AddNotesTypes';
import { EMPTY_VALUE } from 'utils/constants';

export const addNotesSchema = (t: TFunction<'translation'>) =>
  Yup.object().shape({
    notes: Yup.string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('no-credit-card', t('validation.notesCreditCardNotAllowed'), (value) => {
        const detectCreditCard = (input: string): boolean => {
          const creditCardPatterns = [/(^(?=(?:[0-9\s-]*\d[0-9\s-]*){15,17}$)[0-9\s-]+$)/];

          return creditCardPatterns.some((pattern) => pattern.test(input));
        };

        return !value || !detectCreditCard(value);
      })
      .nullable(),
  });

export const addNotesInitialValues = (): AddNotesValues => ({
  [AddNotesFields.Notes]: EMPTY_VALUE,
});
