import { FC } from 'react';
import { HistoryPageContainer } from 'components/notes/historyTab/HistoryTab.styles';
import { CreateHistory } from 'components/notes/historyTab/createHistory/CreateHistory';
import { PaymentHistory } from 'components/notes/historyTab/paymentHistory/PaymentHistory';

export const HistoryTab: FC = () => {
  return (
    <HistoryPageContainer data-testid='historyPage'>
      <CreateHistory />
      <PaymentHistory />
    </HistoryPageContainer>
  );
};
