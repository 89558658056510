import * as Yup from 'yup';
import { QuickResFields, QuickResValues } from './QuickResTypes';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { combineDateAndTime, compareDate, compareDateTime, MixedDateTime } from 'utils/dateUtils';
import { ResLocationDetails } from 'services/location/locationRollupTypes';
import { EMPTY_VALUE } from 'utils/constants';
import { formatCurrency } from 'utils/currencyUtils';
import { PeriodicDistance, PeriodicVehicleRate } from 'services/booking/bookingTypes';
import { Vehicle } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { getTotalDistance } from 'utils/vehicleUtils';

export const quickResValidationSchema = (t: TFunction<'translation'>) =>
  Yup.object().shape({
    lastName: Yup.string()
      .trim()
      .min(2, t('validation.minFieldLength', { fieldLength: 2 }))
      .required(t('validation.requiredField'))
      .matches(/^(\D*)$/i, t('validation.invalidLastName')),
    firstName: Yup.string()
      .trim()
      .min(2, t('validation.minFieldLength', { fieldLength: 2 }))
      .required(t('validation.requiredField'))
      .matches(/^(\D*)$/i, t('validation.invalidFirstName'))
      .nullable()
      .test('length', t('validation.minFieldLength', { fieldLength: 2 }), (val) => (val && val.length >= 2) || !val),
    age: Yup.string().nullable(),
    phone: Yup.string()
      .matches(/^[0-9]*$/, t('validation.positiveNumber'))
      .test('length', t('validation.phoneNumberValidation'), (val) => (val && val.length == 10) || !val)
      .nullable(),
    email: Yup.string().email(t('validation.invalidEmail')).nullable(),
    startDate: Yup.date()
      .required()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .test(QuickResFields.StartDate, t('validation.currentOrFutureDate'), function (value, context) {
        const { currentLocationTime } = context.parent;
        if (value && currentLocationTime) {
          return compareDate(DateTime.fromJSDate(value), currentLocationTime);
        } else {
          return true;
        }
      })
      .min(DateTime.now().startOf('day').toJSDate(), t('validation.currentOrFutureDate'))
      .test(QuickResFields.StartDate, t('validation.requiredField'), function (value) {
        return value !== null;
      }),
    startTime: Yup.mixed<DateTime>()
      .required()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidTimeFormat'))
      .test(QuickResFields.StartTime, t('validation.futureLocationTime'), function (value, context) {
        const { startDate, currentLocationTime, currentLocationTimezone } = context.parent;
        if (value && startDate && currentLocationTime) {
          const startDateTime = combineDateAndTime(DateTime.fromJSDate(startDate), value, currentLocationTimezone);
          return compareDateTime(startDateTime, currentLocationTime);
        } else {
          return true;
        }
      })
      .test(QuickResFields.StartTime, t('validation.requiredField'), function (value) {
        return value !== null;
      }),
    returnDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidDateFormat'))
      .min(DateTime.now().startOf('day').toJSDate(), t('validation.currentOrFutureDate'))
      .test(QuickResFields.ReturnDate, t('validation.returnDateRequired'), function (value, context) {
        const { returnTime } = context.parent;
        return !(!value && returnTime);
      })
      .test(QuickResFields.ReturnDate, t('validation.invalidValue'), function (value, context) {
        const { startDate } = context.parent;
        if (value && startDate) {
          return compareDate(DateTime.fromJSDate(value), DateTime.fromJSDate(startDate));
        } else {
          return true;
        }
      })
      .test(QuickResFields.ReturnDate, t('validation.currentOrFutureDate'), function (value, context) {
        const currentLocationTime: DateTime = context.parent.currentLocationTime;
        if (value && currentLocationTime) {
          return compareDate(DateTime.fromJSDate(value), currentLocationTime.startOf('day'));
        } else {
          return true;
        }
      })
      .test(QuickResFields.ReturnDate, t('validation.invalidValue'), function (value, context) {
        const { startDate, startTime, returnTime } = context.parent;

        if (value && startDate && startTime && returnTime) {
          const pickupDate = DateTime.fromJSDate(startDate).startOf('day');
          const returnDate = DateTime.fromJSDate(value).startOf('day');
          const pickupTime = DateTime.fromJSDate(startTime);
          const returnTimeObj = DateTime.fromJSDate(returnTime);

          if (pickupDate.equals(returnDate)) {
            const timeDifference = returnTimeObj.diff(pickupTime, 'minutes').minutes;
            if (timeDifference < 1) {
              return false;
            }
          }
        }

        return true;
      }),
    returnTime: Yup.mixed<DateTime>()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('validation.invalidTimeFormat'))
      .test(QuickResFields.ReturnTime, t('validation.invalidValue'), function (value, context) {
        const { startDate, startTime, returnDate } = context.parent;
        if (startDate && startTime && returnDate && value) {
          if (DateTime.fromJSDate(startDate).hasSame(DateTime.fromJSDate(returnDate), 'day')) {
            if (value < startTime || value.equals(startTime)) {
              return false;
            }
          }
        }

        return true;
      })
      .test(QuickResFields.ReturnTime, t('validation.invalidValue'), function (_, context) {
        const { startDate, returnDate, startTime, returnTime } = context.parent;

        if (!startDate || !returnDate || !startTime || !returnTime) {
          return true;
        }

        const pickupDateTime = DateTime.fromJSDate(startDate).set({
          hour: startTime.hour,
          minute: startTime.minute,
        });

        const returnDateTime = DateTime.fromJSDate(returnDate).set({
          hour: returnTime.hour,
          minute: returnTime.minute,
        });

        return pickupDateTime <= returnDateTime;
      }),
    preferences: Yup.string().containsCreditCardPattern(t('validation.creditCardNotAllowed')),
  });

export const quickResInitialValues = (
  t: TFunction<'translation'>,
  currentLocation: ResLocationDetails | undefined,
  getLocalizedDateTime: (timezone: string, dateTime?: MixedDateTime) => DateTime | undefined
): QuickResValues => ({
  [QuickResFields.CurrentLocationTimezone]: currentLocation?.timezone ?? 'system',
  [QuickResFields.CurrentLocationUrn]: currentLocation?.urn ?? '',
  [QuickResFields.CurrentLocationTime]: currentLocation?.timezone
    ? (getLocalizedDateTime(currentLocation?.timezone, DateTime.now()) as DateTime)
    : '',
  [QuickResFields.StartDate]: EMPTY_VALUE,
  [QuickResFields.StartTime]: EMPTY_VALUE,
  [QuickResFields.StartDateTime]: EMPTY_VALUE,
  [QuickResFields.ReturnDate]: EMPTY_VALUE,
  [QuickResFields.ReturnTime]: EMPTY_VALUE,
  [QuickResFields.ReturnDateTime]: EMPTY_VALUE,
  [QuickResFields.VehicleClass]: EMPTY_VALUE,
  [QuickResFields.LastName]: EMPTY_VALUE,
  [QuickResFields.FirstName]: EMPTY_VALUE,
  [QuickResFields.Age]: EMPTY_VALUE,
  [QuickResFields.ContactType]: EMPTY_VALUE,
  [QuickResFields.CountryCode]: EMPTY_VALUE,
  [QuickResFields.Phone]: EMPTY_VALUE,
  [QuickResFields.AccountName]: '--',
  [QuickResFields.AccountNumber]: EMPTY_VALUE,
  [QuickResFields.AccountType]: t('rateAndBilling.retail'),
  [QuickResFields.Preferences]: EMPTY_VALUE,
});

export const getRateString = (vehicle: Vehicle, t: TFunction<'translation'>, locale: string): string => {
  const periodicRate = vehicle ? (vehicle.vehicleRates?.paylater?.vehicleRate as PeriodicVehicleRate) : undefined;
  const rates = [];
  if (periodicRate) {
    const costPerDay = periodicRate?.costPerDay || periodicRate?.extraTimeRate?.costPerDay;
    if (costPerDay?.amount) {
      rates.push(formatCurrency(costPerDay?.amount, costPerDay?.currencyCode ?? '', locale) + t('rates.perDay'));
    }
    if (periodicRate?.costPerWeek?.amount) {
      rates.push(
        formatCurrency(periodicRate?.costPerWeek?.amount, periodicRate?.costPerWeek?.currencyCode ?? '', locale) +
          t('rates.perWeek')
      );
    }
    if (periodicRate?.costPerMonth?.amount) {
      rates.push(
        formatCurrency(periodicRate?.costPerMonth?.amount, periodicRate?.costPerMonth?.currencyCode ?? '', locale) +
          t('rates.perMonth')
      );
    }
    if (periodicRate?.costPerHour?.amount) {
      rates.push(
        formatCurrency(periodicRate?.costPerHour?.amount, periodicRate?.costPerHour?.currencyCode ?? '', locale) +
          t('rates.perHour')
      );
    }
    return rates.length ? rates.map(String).join(', ') : '--';
  } else {
    return '--';
  }
};

export const getDistanceAllotmentString = (vehicle: Vehicle, t: TFunction<'translation'>): string => {
  const periodicRate = vehicle ? (vehicle.vehicleRates?.paylater?.vehicleRate as PeriodicVehicleRate) : undefined;
  const includedDistanceRates: PeriodicDistance | undefined = periodicRate?.includedDistance;
  if (periodicRate?.totalIncludedDistance?.value && periodicRate?.totalIncludedDistance?.value > 0) {
    return t('vehicle.limitedMileage');
  } else if (includedDistanceRates && getTotalDistance(includedDistanceRates) > 0) {
    return t('vehicle.limitedMileage');
  } else {
    return t('vehicle.unlimitedMileage');
  }
};
