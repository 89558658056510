import { Box, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const NoHistoryTextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${ehiTheme.spacing(4)};
  align-self: stretch;
  padding: ${ehiTheme.spacing(3)};
`;
