import { useTranslations } from 'components/shared/i18n';
import { FC, useState } from 'react';
import { ButtonBox, StyledAddNoteButton } from 'components/notes/addNote/AddNoteButton.styles';
import AddIcon from '@mui/icons-material/Add';
import { AddNotesDialog } from 'components/notes/addNote/AddNotesDialog';

export const AddNoteButton: FC = () => {
  const [notesOpen, setNotesOpen] = useState<boolean>(false);
  const handleClick: () => void = () => {
    setNotesOpen(true);
  };
  const handleCancelDialog = (): void => {
    setNotesOpen(false);
  };

  const { t } = useTranslations();
  return (
    <ButtonBox data-testid='addNoteButton'>
      <StyledAddNoteButton variant='extended' onClick={handleClick}>
        <AddIcon />
        {t('notes.addNote')}
      </StyledAddNoteButton>
      {notesOpen && <AddNotesDialog open={notesOpen} onClose={handleCancelDialog} />}
    </ButtonBox>
  );
};
