import { ChangeEvent, FC, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Caption } from '@ehi/ui';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import {
  AddPreferencesHelperText,
  AddPreferencesTextBox,
  AddPreferencesTextField,
} from 'components/notes/notesTab/cards/preferenceCard/PreferencesCard.styles';
import { updateRemarkForReservationEditor } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectRemarks } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

const MAX_CHAR_LENGTH = 150;

export type PreferencesDialogProps = {
  onClose: () => void;
};

export const AddPreferencesDialog: FC<PreferencesDialogProps> = ({ onClose }) => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const preferences = useAppSelector(selectRemarks);

  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const [preferencesInput, setPreferencesInput] = useState(preferences);
  const [loading, setLoading] = useState(false);

  const handleChangingPreferences = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setPreferencesInput(e.target.value);
  };

  const onFormSubmit = async (): Promise<void> => {
    setLoading(true);
    const { errors } = await updateAndRefresh(async (): Promise<void> => {
      await updateRemarkForReservationEditor(bookingEditorId, preferencesInput);
    });
    if (errors) {
      setLoading(false);
      await showAlert({ responseMessages: errors });
    } else {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      a11yKey={'add-preferences'}
      data-testid='addPreferencesDialog'
      open={true}
      fullWidth
      maxWidth={'sm'}
      title={t('notes.addPreferences')}
      contentPadding={0}
      actions={{
        primaryAction: {
          label: t('common.submit'),
          onClick: onFormSubmit,
          overrideButtonProps: { disabled: preferences === preferencesInput },
        },
        secondaryAction: { label: t('common.cancel'), onClick: onClose },
      }}>
      <AddPreferencesTextBox>
        <AddPreferencesTextField
          data-testid='preferencesInput'
          fullWidth
          size={'medium'}
          value={preferencesInput}
          placeholder={t('notes.preferences')}
          multiline
          rows={6}
          onChange={handleChangingPreferences}
          inputProps={{
            maxLength: MAX_CHAR_LENGTH,
            'aria-label': 'add-preferences',
            'data-testid': 'add-preferences',
          }}
        />
        <AddPreferencesHelperText>
          <Caption data-testid='remainingChars'>
            {preferencesInput?.length ?? 0}/{MAX_CHAR_LENGTH}
          </Caption>
        </AddPreferencesHelperText>
        <ProgressOverlay inProgress={loading} />
      </AddPreferencesTextBox>
    </Dialog>
  );
};
