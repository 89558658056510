import { FC } from 'react';
import { Grid } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';
import { Box, useMediaQuery } from '@mui/system';
import { useTranslations } from 'components/shared/i18n';
import { DOUBLE_DASH } from 'utils/constants';
import { StyledLink } from 'components/shared/ui/styles/Global.styles';
import {
  HistoryCaption,
  HistoryGridItem,
  InfoIcon,
} from 'components/notes/historyTab/createHistory/CreateHistory.styles';
import { toDateTimeString } from 'utils/dateUtils';
import { parseUrn } from 'utils/urnUtils';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import {
  selectAlternateReferences,
  selectPointOfSale,
  selectPriorReservationNumber,
  selectSource,
} from 'redux/selectors/bookingEditor';
import { RouterPaths } from 'app/router/RouterPaths';
import { generateSearchParams } from 'utils/routing/urlUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { HistoryCard } from 'components/notes/historyTab/HistoryTab.styles';

export const CreateHistory: FC = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const source = useAppSelector(selectSource);
  const pointOfSale = useAppSelector(selectPointOfSale);
  const alternateReferences = useAppSelector(selectAlternateReferences);
  const priorReservationNumber = useAppSelector(selectPriorReservationNumber);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const itemDisplay = (serviceValue: string | undefined): string => {
    return serviceValue ?? DOUBLE_DASH;
  };
  const linkDisplay = (serviceValue: string | undefined): JSX.Element => {
    return serviceValue ? (
      <StyledLink as='button' data-testid={'prev-res-link'}>
        <Body1
          bold
          onClick={(): void =>
            navigate(
              `${RouterPaths.PreProcessor}?${generateSearchParams({
                [InternalTransactionParams.TransactionType]: TransactionTypes.Search,
                [InternalTransactionParams.Res]: serviceValue,
              })}`
            )
          }>
          {serviceValue}
        </Body1>
      </StyledLink>
    ) : (
      <Body1>{DOUBLE_DASH}</Body1>
    );
  };

  return (
    <HistoryCard sx={{ border: '1px solid #0000003b', boxShadow: 'none', width: isMobileOrTablet ? '100%' : '75%' }}>
      <Box sx={{ padding: ehiTheme.spacing(2) }}>
        <Grid container data-testid='createSection'>
          <HistoryGridItem data-testid='createdByField'>
            <HistoryCaption>{t('notes.history.createdBy')}</HistoryCaption>
            <Body1>{source?.createdBy}</Body1>
          </HistoryGridItem>
          <HistoryGridItem data-testid='createdDateTimeField'>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <HistoryCaption>{t('notes.history.createdDateTime')}</HistoryCaption>
              <InfoIcon />
            </Box>
            <Body1>{toDateTimeString(source?.createdDateTime, t('format.dateTimeWithZone'))}</Body1>
          </HistoryGridItem>
          <HistoryGridItem data-testid='bookingStationField'>
            <HistoryCaption>{t('notes.history.bookingStation')}</HistoryCaption>
            <Body1>{parseUrn(pointOfSale?.location)}</Body1>
          </HistoryGridItem>
        </Grid>
        <Grid container data-testid='bookingAndResSection'>
          <HistoryGridItem data-testid='bookingChannelField'>
            <HistoryCaption>{t('notes.history.bookingChannel')}</HistoryCaption>
            <Body1>{source?.legacyApplication}</Body1>
          </HistoryGridItem>
          <HistoryGridItem data-testid='altResNumberField'>
            <HistoryCaption>{t('notes.history.altResNumber')}</HistoryCaption>
            <Body1>{itemDisplay(alternateReferences?.replacementRentalReference)}</Body1>
          </HistoryGridItem>
          <HistoryGridItem data-testid='prevResNumberField'>
            <HistoryCaption>{t('notes.history.prevResNumber')}</HistoryCaption>
            <br />
            {linkDisplay(parseUrn(priorReservationNumber))}
          </HistoryGridItem>
        </Grid>
        <Grid container data-testid='recordAndTourSection'>
          <Grid item xs={12} sm={4} data-testid='gdsRecordLocatorField'>
            <HistoryCaption>{t('notes.history.gdsRecordLocator')}</HistoryCaption>
            <Body1>{itemDisplay(alternateReferences?.passengerNameRecordReference)}</Body1>
          </Grid>
          <Grid item xs={12} sm={4} data-testid='tourAgentIdField'>
            <HistoryCaption>{t('notes.history.tourAgentId')}</HistoryCaption>
            <Body1>{itemDisplay(pointOfSale?.tourAgentNumber)}</Body1>
          </Grid>
        </Grid>
      </Box>
    </HistoryCard>
  );
};
