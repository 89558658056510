export enum RouterPaths {
  // Admin Endpoints
  Empty = '',
  SupportTicket = '/support',
  Logout = '/logout',
  Error = '/error',
  DeepLink = '/deeplink',
  NetworkError = '/network-error',

  // Location
  SetLocation = '/set-location',
  AccessDenied = '/no-access',

  // Preprocessor Routes
  PreProcessor = '/res/pre-processor',

  // Sub routes
  BasePath = '/res/:resNumber',
  Search = '/res/search', // Search
  Create = '/res/create', // Create
  View = '/res/:resNumber/view', // View
  Modify = '/res/:resNumber/modify', // Modify

  // View/Modify Flex Routes
  WhenAndWhere = 'when-and-where',
  Driver = 'driver',
  RateAndBill = 'rate-and-bill',
  Vehicle = 'vehicle',
  AddOns = 'add-ons',
  Payment = 'payment',
  Review = 'review',

  // Notes tabs
  Notes = 'notes',

  // dev
  Dev = '/dev',
  SimpleHookForm = '/res/search/form',
}

export const SIBLING_PREFIX = '../';

export enum IFRAME_ROUTES {
  quickRes = '/iframe/quick-res',
  iframe = '/iframe/res',
}

export enum HashPaths {
  QuickRes = '#QuickRes',
  AddDriver = '#AddDriver',
  ReplaceDriver = '#ReplaceDriver',
}
