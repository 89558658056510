import { ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { RadioGroup } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { GridItem, GridVerticalContainer } from 'components/shared/ui/styles/Grid.styles';
import { ConsentFields, ConsentProps, ConsentStatus } from 'components/flexFlow/driver/consent/ConsentFormTypes';
import { useBranchInfoByUrnQuery, useCountriesQuery } from 'services/location/locationQueries';
import { EMPTY_VALUE } from 'utils/constants';
import { generateCountryMenuItemsWithCorporate } from 'utils/formUtils';
import { SelectField } from 'components/shared/forms/SelectField';
import { Option } from 'components/shared/ui/OptionTypes';
import { Phone } from 'components/shared/uiModels/driver/driverDataTypes';
import { CORPORATE_COUNTRIES, CorporateCountry } from 'utils/countryUtils';
import { getCountryCode } from 'utils/locationUtils';
import { useAppSelector } from 'redux/hooks';
import { selectPickup } from 'redux/selectors/bookingEditor';
import { AddressType } from 'services/location/locationTypes';

export const ConsentRadioGroup: FC<ConsentProps> = ({ driverDetails }) => {
  const { t } = useTranslations();
  const { setValue, watch } = useFormContext();
  const isAccepted = watch(ConsentFields.Status);
  const { data } = useCountriesQuery();
  const selectedCountry = watch(ConsentFields.Country);
  const pickupData = useAppSelector(selectPickup);
  const { data: pickup } = useBranchInfoByUrnQuery(pickupData?.branch ?? EMPTY_VALUE);

  const countryOptions = useMemo<Option<string>[]>(() => {
    if (!data?.length) return [];

    return generateCountryMenuItemsWithCorporate(data);
  }, [data]);

  const getPrimaryPhoneNumber = useMemo((): Phone | undefined => {
    if (Object.values(CORPORATE_COUNTRIES).includes(driverDetails?.primaryPhone?.country as CorporateCountry)) {
      return driverDetails?.primaryPhone;
    }
    return undefined;
  }, [driverDetails]);

  const acceptedOnClick = useCallback((): void => {
    setValue(ConsentFields.Status, ConsentStatus.Accepted);
    const phone = getPrimaryPhoneNumber;
    setValue(ConsentFields.Country, phone?.country ?? getCountryCode(pickup?.addresses, AddressType.PHYSICAL));
    setValue(ConsentFields.PhoneNumber, phone?.number ?? EMPTY_VALUE);
  }, [getPrimaryPhoneNumber, pickup?.addresses, setValue]);

  const declinedOnClick = (): void => {
    setValue(ConsentFields.Status, ConsentStatus.Declined);
    setValue(ConsentFields.Country, EMPTY_VALUE);
    setValue(ConsentFields.PhoneNumber, EMPTY_VALUE);
  };

  return (
    <RadioGroup data-testid='consent-status' style={{ paddingTop: ehiTheme.spacing(1) }}>
      <Caption2>{t('consent.consentStatus')}</Caption2>
      <FormControlLabel
        value={ConsentStatus.Accepted}
        control={<Radio color='primary' data-testid={'consent-accepted-radio'} />}
        label={t('consent.accepted')}
        onClick={acceptedOnClick}
      />
      {isAccepted == ConsentStatus.Accepted && (
        <GridVerticalContainer>
          <GridItem>
            <SelectField
              name={ConsentFields.Country}
              label={t('consent.countryCode')}
              data-testid={'consentCountry'}
              options={countryOptions}
            />
          </GridItem>
          <GridItem>
            <PhoneTextField
              country={selectedCountry}
              name={ConsentFields.PhoneNumber}
              label={t('consent.phoneNumber')}
              data-testid={'consentPhoneNumber'}
            />
          </GridItem>
        </GridVerticalContainer>
      )}
      <FormControlLabel
        value={ConsentStatus.Declined}
        control={<Radio color='primary' data-testid={'consent-declined-radio'} />}
        label={t('consent.declined')}
        onClick={declinedOnClick}
      />
    </RadioGroup>
  );
};
