import { InnerStepContainer, StepContent } from 'components/notes/step/Step.styles';
import { StepIndicator } from 'components/notes/step/StepIndicator';
import { FunctionComponent, ReactNode } from 'react';

type StepProps = {
  children?: ReactNode;
  first?: boolean;
  last?: boolean;
};

export const Step: FunctionComponent<StepProps> = ({ first, last, children }: any) => {
  return (
    <InnerStepContainer data-testid={'innerStepContainer'}>
      <StepIndicator data-testid={'stepIndicator'} first={first} last={last} />
      <StepContent data-testid={'stepContent'}>{children}</StepContent>
    </InnerStepContainer>
  );
};
