import { Box } from '@mui/material';
import { FC } from 'react';
import { SpacingWrapperProps } from './ReservationRouteTypes';
import { useNotes } from 'components/notes/useNotes';

export const ReservationPageSpacingWrapper: FC<SpacingWrapperProps> = ({ spacingEnabled = false, children }) => {
  const { isNotesPage } = useNotes();

  if (isNotesPage) {
    return (
      <Box
        sx={{
          height: '90vh',
          background: '#fff',
        }}>
        {children}
      </Box>
    );
  }

  return (
    // Extra bottom margin added to ensure users can get to information on
    // screen without being blocked by floating button (so they can scroll more)
    <>
      {spacingEnabled ? (
        <Box sx={{ marginBottom: (theme) => theme.spacing(17), padding: 0 }}>{children}</Box>
      ) : (
        children
      )}
    </>
  );
};
