import { FC, ReactElement, useMemo } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { addNotesInitialValues, addNotesSchema } from 'components/notes/addNote/addNotesUtils';
import { AddNotesFields, AddNotesValues } from 'components/notes/addNote/AddNotesTypes';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { GridHorizontalContainer } from 'components/shared/ui/styles/Grid.styles';
import {
  AddNotesHelperText,
  AddNotesTextBox,
  AddNotesTextField,
  NotesInputAdornment,
  StyledGridItem,
} from 'components/shared/notes/NotesButton.styles';
import { Caption, ehiTheme } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { ERROR_ALERT_VARIANT } from 'components/shared/alert/AlertDialogTypes';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Box } from '@mui/material';

interface AddNotesDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddNotesDialog: FC<AddNotesDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslations();
  const resolver = useYupValidationResolver(addNotesSchema(t));
  const NOTES_MAX_CHARS = 500;

  const formInitialValues: AddNotesValues = useMemo(() => addNotesInitialValues(), []);

  const formMethods = useForm<AddNotesValues>({
    resolver: resolver,
    defaultValues: formInitialValues,
  });

  const notes = formMethods.watch(AddNotesFields.Notes);

  const onFormSubmit = (): void => {
    // Will be implemented in a later story
    console.log('Testing Add Note Submit Button');
  };

  return (
    <Dialog
      data-testid='add-note-dialog'
      contentPadding={0}
      open={open}
      a11yKey='content'
      title={t('notes.addNote')}
      maxWidth={'sm'}
      fullWidth
      actions={{
        secondaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
        primaryAction: {
          label: t('common.submit'),
          onClick: onFormSubmit,
        },
      }}>
      <FormProvider {...formMethods}>
        <Controller
          control={formMethods.control}
          name={AddNotesFields.Notes}
          render={({ field, fieldState }): ReactElement => (
            <Box style={{ paddingTop: ehiTheme.spacing(0.5) }}>
              <AddNotesTextBox>
                <AddNotesTextField
                  {...field}
                  fullWidth
                  size={'medium'}
                  value={field.value || ''}
                  label={t('notes.note')}
                  error={!!fieldState.error}
                  multiline
                  rows={12}
                  InputProps={{
                    endAdornment: !!fieldState.error?.message && (
                      <NotesInputAdornment position={'end'}>
                        <ErrorOutlinedIcon color={ERROR_ALERT_VARIANT} fontSize={'medium'} />
                      </NotesInputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: NOTES_MAX_CHARS,
                  }}
                  onBlur={async (): Promise<void> => {
                    await formMethods.trigger(AddNotesFields.Notes);
                  }}
                />
                <GridHorizontalContainer>
                  <StyledGridItem
                    sm={9}
                    justifyContent={'flex-start'}
                    sx={{ visibility: fieldState.error?.message ? 'visible' : 'hidden' }}>
                    <AddNotesHelperText>
                      <ErrorOutlinedIcon
                        color={ERROR_ALERT_VARIANT}
                        fontSize={'small'}
                        style={{ padding: ehiTheme.spacing(0, 1, 0, 0) }}
                      />
                      <Caption data-testid='errorMessage' color={'#B00020'}>
                        {fieldState.error?.message}
                      </Caption>
                    </AddNotesHelperText>
                  </StyledGridItem>
                  <StyledGridItem sm={3} justifyContent={'flex-end'}>
                    <AddNotesHelperText>
                      <Caption data-testid='remainingChars'>
                        {(notes as string).length ?? 0}/{NOTES_MAX_CHARS}
                      </Caption>
                    </AddNotesHelperText>
                  </StyledGridItem>
                </GridHorizontalContainer>
              </AddNotesTextBox>
            </Box>
          )}
        />
      </FormProvider>
    </Dialog>
  );
};
