import { VehicleInfoContainer } from 'components/flexFlow/vehicle/VehiclePage.styles';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body2, ehiTheme } from '@ehi/ui';
import { RateCard, RateLabel } from 'components/flexFlow/vehicle/RateCard';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import {
  PeriodicDistance,
  PeriodicVehicleRate,
  PotentiallyAdjustableAmount,
  VehicleRate,
} from 'services/booking/bookingTypes';
import { FC, useCallback, JSX, ReactElement } from 'react';
import { VehicleSectionProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { getCarClassAndDescription, getTotalDistance } from 'utils/vehicleUtils';
import { useAlert } from 'components/shared/alert/AlertContext';
import { VehicleInfoModalContent } from './VehicleInfoModalContent';
import { StyledLink } from 'components/shared/ui/styles/Global.styles';

export const VehicleInfo: FC<VehicleSectionProps> = ({ vehicles }) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();

  /* Retrieve vehicle class if one is selected */
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const vehicleClass = getCarClassAndDescription(parseUrn(vehicleClassSelection?.preferred), vehicles);
  const rate: VehicleRate | undefined = vehicleClassSelection?.rate;
  const vehiclePeriodRate: PeriodicVehicleRate | undefined = rate && (rate as PeriodicVehicleRate);
  const includedDistanceRates: PeriodicDistance | undefined = vehiclePeriodRate?.includedDistance;
  const amountPerExcessDistance: PotentiallyAdjustableAmount | undefined =
    vehicleClassSelection?.rate?.amountPerExcessDistance;

  const handleOpenVehicleDetails = useCallback(() => {
    showAlert({
      title: t('common.vehicleInformation'),
      description: <VehicleInfoModalContent vehicles={vehicles} />,
    });
  }, [showAlert, vehicles, t]);

  const getDistanceAllotment = (vehicleClass: string): ReactElement => {
    if (rate?.totalIncludedDistance?.value && rate?.totalIncludedDistance?.value > 0) {
      return (
        <div style={{ display: 'flex' }}>
          {rate?.totalIncludedDistance?.value && (
            <Body2 bold data-testid={'vehicleInfoTotalIncludedDistance'}>{`${rate?.totalIncludedDistance.value}${t(
              'rates.perRental'
            )}`}</Body2>
          )}
          {amountPerExcessDistance && amountPerExcessDistance.amount && (
            <>
              <span>,&nbsp;</span>
              <RateLabel
                amount={amountPerExcessDistance.amount}
                currency={amountPerExcessDistance.currencyCode ?? ''}
                period={t('rates.perExcess')}
              />
            </>
          )}
        </div>
      );
    } else if (includedDistanceRates && getTotalDistance(includedDistanceRates) > 0) {
      const lineItems = new Array<JSX.Element>();

      if (includedDistanceRates.distancePerDay && includedDistanceRates.distancePerDay > 0) {
        lineItems.push(
          <div key={'vehicleInfoRatesPerDay'}>
            <Body2 bold data-testid={'vehicleInfoRatesPerDay'}>{`${includedDistanceRates.distancePerDay}${t(
              'rates.perDay'
            )}`}</Body2>
          </div>
        );
      }
      if (includedDistanceRates.distancePerWeek && includedDistanceRates.distancePerWeek > 0) {
        lineItems.push(
          <div key={'vehicleInfoRatesPerWeek'}>
            <span>,&nbsp;</span>;
            <Body2 bold data-testid={'vehicleInfoRatesPerWeek'}>{`${includedDistanceRates.distancePerWeek}${t(
              'rates.perWeek'
            )}`}</Body2>
          </div>
        );
      }
      if (includedDistanceRates.distancePerMonth && includedDistanceRates.distancePerMonth > 0) {
        lineItems.push(
          <div key={'vehicleInfoRatesPerMonth'}>
            <span>,&nbsp;</span>
            <Body2 bold data-testid={'vehicleInfoRatesPerMonth'}>{`${includedDistanceRates.distancePerMonth}${t(
              'rates.perMonth'
            )}`}</Body2>
          </div>
        );
      }
      return (
        <div style={{ display: 'flex' }}>
          {lineItems}
          {amountPerExcessDistance && amountPerExcessDistance.amount && (
            <>
              <span>,&nbsp;</span>
              <RateLabel
                amount={amountPerExcessDistance.amount}
                currency={amountPerExcessDistance.currencyCode ?? ''}
                period={t('rates.perExcess')}
              />
            </>
          )}
        </div>
      );
    } else {
      return (
        <Body2 bold data-testid={'vehicleInfoUnlimitedLabel'}>
          {vehicleClass ? t('vehicle.unlimitedMileage') : ''}
        </Body2>
      );
    }
  };

  return (
    <VehicleInfoContainer data-testid={'vehicleInfo'}>
      <h3>{t('common.vehicleInformation')}</h3>
      <Grid container paddingTop={ehiTheme.spacing(1)} paddingBottom={ehiTheme.spacing(1)}>
        <Grid item xs={4} display={'flex'} flexDirection={'column'} alignItems={'start'}>
          <Caption2>{t('vehicle.vehicleClass')}</Caption2>
          {vehicleClass && (
            <StyledLink as='button' onClick={handleOpenVehicleDetails} data-testid={'vehicle-class-link'}>
              <Body2 bold>{vehicleClass}</Body2>
            </StyledLink>
          )}
        </Grid>
      </Grid>
      <Grid container padding={ehiTheme.spacing(1, 0)}>
        <Grid item xs={6}>
          <Caption2>{t('vehicle.currentRate')}</Caption2>
          {vehiclePeriodRate && <RateCard rate={vehiclePeriodRate} />}
        </Grid>
        <Grid item xs={4}>
          <Caption2>{t('vehicle.distanceAllotment')}</Caption2>
          {getDistanceAllotment(vehicleClass)}
        </Grid>
      </Grid>
    </VehicleInfoContainer>
  );
};
