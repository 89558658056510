import { Body2, EhiButton, H6 } from '@ehi/ui';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FC, useMemo, useState } from 'react';
import { BranchV2 } from 'services/location/locationTypes';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { RentalLocationAddress } from './RentalLocationAddress';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import { combineDateAndTime, isInvalidDateTime, toDateTimeString } from 'utils/dateUtils';
import { WhenAndWhereFields } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { BranchLookupModal } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupModal';
import { useDriverLicenseValidation } from 'hooks/driverLicenseValidation/useDriverLicenseValidation';

type RentalStartSectionProps = {
  pickupBranch: BranchV2 | undefined;
  pickupDateTime: string | undefined;
  handleRentalStartLocation: (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ) => void;
};

export const RentalStartSection: FC<RentalStartSectionProps> = ({
  pickupBranch,
  pickupDateTime,
  handleRentalStartLocation,
}) => {
  const { t } = useTranslations();
  const { setValue, getValues, trigger, setError } = useFormContext();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const [showStartLocationModal, setShowStartLocationModal] = useState(false);
  const [startLocationTimezone] = useWatch({ name: [WhenAndWhereFields.StartLocationTimezone] });
  const { validateRentalDatesAgainstLicenseExpiration } = useDriverLicenseValidation();

  const formattedPickupDate = useMemo(
    () => toDateTimeString(pickupDateTime, t('format.shortWeekdayDate'), pickupBranch?.timezone),
    [pickupBranch?.timezone, pickupDateTime, t]
  );
  const formattedPickupTime = useMemo(
    () => toDateTimeString(pickupDateTime, t('format.timeWithZone'), pickupBranch?.timezone),
    [pickupBranch?.timezone, pickupDateTime, t]
  );

  const combineDateTime = (date: DateTime | '', time: DateTime | ''): void => {
    if (isInvalidDateTime(time)) {
      setError(WhenAndWhereFields.StartTime, { message: t('validation.invalidTimeFormat') });
    } else {
      const combined = date && time ? combineDateAndTime(date, time, pickupBranch?.timezone) : '';
      setValue(WhenAndWhereFields.StartDateTime, combined);
    }
  };

  const handleDateChange = async (date: DateTime | ''): Promise<void> => {
    setValue(WhenAndWhereFields.StartDate, date);
    await trigger([WhenAndWhereFields.StartTime]);
    const time = getValues(WhenAndWhereFields.StartTime);
    combineDateTime(date, time);
    validateRentalDatesAgainstLicenseExpiration(date.toString(), getValues(WhenAndWhereFields.ReturnDate));
  };

  const handleTimeChange = async (time: DateTime | ''): Promise<void> => {
    await trigger([WhenAndWhereFields.StartDate]);
    const date = getValues(WhenAndWhereFields.StartDate);
    setValue(WhenAndWhereFields.StartTime, time);
    combineDateTime(date, time);
  };

  return (
    <GridContainer data-testid={'rentalStartSection'}>
      {isReadOnly ? (
        <GridItem xs={12} sm={12} md={12}>
          <Caption2 display={'block'}>{t('whenWhere.rentalStart')}</Caption2>
          <div data-testid='pickupDate'>
            <Body2 bold display='inline' noWrap={true}>
              {formattedPickupDate}{' '}
            </Body2>
            <Body2 display='inline' noWrap={true}>
              {formattedPickupTime}
            </Body2>
          </div>
          {pickupBranch && <RentalLocationAddress branch={pickupBranch} />}
        </GridItem>
      ) : (
        <>
          <GridItem xs={12} sm={12} md={12} data-testid={'rentalStartLocation'}>
            <H6 data-testid={'rentalStartHeader'}>{t('whenWhere.rentalStart')}</H6>
            <Caption2 data-testid={'rentalStartLocationLabel'}>{t('whenWhere.startLocation')}</Caption2>
            <EhiButton data-testid={'rentalStartLocationEdit'} onClick={(): void => setShowStartLocationModal(true)}>
              {t('common.edit')}
            </EhiButton>
            {pickupBranch && <RentalLocationAddress branch={pickupBranch} />}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} data-testid={'rentalStartDatePicker'}>
            <DatePickerField
              disablePast
              disabled={!pickupBranch}
              data-testid={'rentalStartDate'}
              name={WhenAndWhereFields.StartDate}
              label={t('whenWhere.date')}
              submitOnChange={handleDateChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} data-testid={'rentalStartTimePicker'}>
            {/* startLocationTimezone is set as undefined as the form is loading after the RentalStartSection component, so reading timezone from pickupBranch*/}
            <TimePickerField
              disabled={!pickupBranch}
              id={'rentalStartTime'}
              name={WhenAndWhereFields.StartTime}
              label={t('whenWhere.time')}
              submitOnChange={handleTimeChange}
              onSubmitTime={handleTimeChange}
              timezone={startLocationTimezone ?? (pickupBranch && pickupBranch?.timezone)}
            />
          </GridItem>
        </>
      )}
      {showStartLocationModal && (
        <BranchLookupModal
          open={showStartLocationModal}
          pickupOrDropOffLocation={WhenAndWhereFields.StartLocation}
          handleCancel={(): void => setShowStartLocationModal(false)}
          title={t('whenWhere.branchLookup')}
          handleApply={(
            branchUrn: string,
            stationId: string,
            locationCurrentTime: DateTime | undefined,
            timezone?: string
          ): void => {
            setShowStartLocationModal(false);
            handleRentalStartLocation(branchUrn, stationId, locationCurrentTime, timezone);
          }}
        />
      )}
    </GridContainer>
  );
};
