import { FC, useState } from 'react';
import { CardContainer } from 'components/notes/notesTab/NotesTab.styles';
import { useTranslations } from 'components/shared/i18n';
import { AddPreferencesDialog } from 'components/notes/notesTab/cards/preferenceCard/AddPreferencesDialog';
import {
  PreferencesActionButton,
  PreferencesIcon,
  PreferencesIconContainer,
  PreferencesText,
  StyledPreferencesCard,
} from './PreferencesCard.styles';
import { Box } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectRemarks } from 'redux/selectors/bookingEditor';

export const PreferencesCard: FC = () => {
  const { t } = useTranslations();
  const isReadOnlyFlow = useAppSelector(selectIsReadOnlyFlow);
  const [showAddPreferencesDialog, setShowAddPreferencesDialog] = useState(false);
  const preferences = useAppSelector(selectRemarks);

  const handleClose = (): void => {
    setShowAddPreferencesDialog(false);
  };

  return (
    <Box display={'flex'}>
      <PreferencesIconContainer>
        <PreferencesIcon />
      </PreferencesIconContainer>
      <CardContainer data-testid={`cardContainer-preferences`} style={{ flex: 'auto' }}>
        <StyledPreferencesCard variant={'outlined'}>
          <PreferencesText hasPreferences={!!preferences}>
            {preferences ? preferences : t('notes.preferencesDefaultText')}
          </PreferencesText>
          {!isReadOnlyFlow && (
            <PreferencesActionButton
              data-testid='preferencesActionButton'
              onClick={(): void => setShowAddPreferencesDialog(true)}>
              {preferences ? t('common.edit') : t('common.add')}
            </PreferencesActionButton>
          )}
        </StyledPreferencesCard>
      </CardContainer>
      {showAddPreferencesDialog && <AddPreferencesDialog onClose={handleClose} />}
    </Box>
  );
};
