import Tab from '@mui/material/Tab';
import { createElement, FunctionComponent, useState } from 'react';
import { NotesPage, TabsBar } from 'components/notes/Notes.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getTabs, TabProps } from 'components/notes/notesUtil';
import { useTranslations } from 'components/shared/i18n';
import { NOTES } from 'utils/constants';

export const Notes: FunctionComponent = () => {
  const { t } = useTranslations();
  const theme = useTheme();
  const tabs = getTabs(t);
  const [activeTab, setActiveTab] = useState<string>(NOTES);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const activeTabComponent = tabs.find((tab: TabProps) => tab.value === activeTab)?.component;

  const onChange = (_: any, nextActiveTab: string): void => {
    setActiveTab(nextActiveTab);
  };

  return (
    <NotesPage data-testid='notesPage'>
      <TabsBar
        data-testid='preferencesTabs'
        value={activeTab}
        variant={isSmallScreen ? 'scrollable' : 'fullWidth'}
        onChange={onChange}
        indicatorColor='primary'
        textColor='primary'>
        {tabs.map((tab: TabProps) => (
          <Tab label={tab.titleKey} key={tab.value} value={tab.value} />
        ))}
      </TabsBar>
      {activeTabComponent && createElement(activeTabComponent)}
    </NotesPage>
  );
};
