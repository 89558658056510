import { FC } from 'react';
import { SpinnerContainer, StyledSpinner } from './spinner.styles';
import { Backdrop } from '@mui/material';

export type ProgressOverlayProps = {
  inProgress: boolean;
  overrideZindex?: boolean;
};

export const ProgressOverlay: FC<ProgressOverlayProps> = ({ inProgress, overrideZindex }: ProgressOverlayProps) => {
  return inProgress ? (
    <Backdrop
      open={inProgress}
      data-testid={'in-progress'}
      // As per mui documentation tooltip has the max Zindex as 1500, for safety we are using 1500 to display spinner
      sx={{ backgroundColor: 'default', zIndex: overrideZindex ? 1500 : 1300 }}>
      <SpinnerContainer>
        <StyledSpinner />
      </SpinnerContainer>
    </Backdrop>
  ) : null;
};
