import * as Yup from 'yup';

// Addall custom validations here
declare module 'yup' {
  interface StringSchema {
    containsCreditCardPattern(message: string): Yup.StringSchema<string | undefined, Yup.AnyObject>;
  }
}

Yup.addMethod(
  Yup.string,
  'containsCreditCardPattern',
  function containsCreditCardPattern(this: Yup.StringSchema, message: string) {
    return this.test('containsCreditCardPattern', message, (value) => {
      const detectCreditCard = (input: string): boolean => {
        const creditCardPatterns = [
          /(?:^|\D)(\d{15,17})(?:\D|$)/,
          /(?:^|\D)(\d{4}[-\s]?\d{4}[-\s]?\d{4}[-\s]?\d{3,5})(?:\D|$)/,
        ];

        return creditCardPatterns.some((pattern) => pattern.test(input));
      };

      return !value || !detectCreditCard(value);
    });
  }
);
